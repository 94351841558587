const mutations = {
  setCompletedFlag(state, input) {
    state.completed = input;
  },
  setSlug(state, input) {
    state.slug = input;
  }
};

export default mutations;
