<template>
  <DropdownMenu name="Status">
    <DropdownMenuItem
      name="Solved"
      value="solved:true" />

    <DropdownMenuItem
      name="Unsolved"
      value="solved:false" />
  </DropdownMenu>
</template>

<script>
import DropdownMenu from "./dropdown-menu";
import DropdownMenuItem from "./dropdown-menu-item";

export default {
  name: "PracticeStatusMenu",
  components: {
    DropdownMenu,
    DropdownMenuItem
  }
}
</script>
