import { getCookie, setCookie } from "modules/cookie";

export const setCouponCookie = () => {
  const COUPON_COOKIE_NAME = "coupon_code";

  if(getCookie(COUPON_COOKIE_NAME)) {
    return;
  }

  const $couponCodeContainer = document.querySelector("[data-promo-coupon-code]");

  if(!$couponCodeContainer) {
    return;
  }

  const couponCode = $couponCodeContainer.dataset.promoCouponCode;

  if(!couponCode) {
    return;
  }

  setCookie(COUPON_COOKIE_NAME, couponCode, 1);
}

setCouponCookie();
