(function() {
  const $loginForm = document.querySelector(".form");

  if(!$loginForm) {
    return;
  }

  $loginForm.addEventListener("click", (e) => {
    const $togglePasswordContainer = e.target && e.target.closest(".js-toggle-password-visibility");

    if($togglePasswordContainer) {
      const $showPasswordIcon = $togglePasswordContainer.querySelector(".form__eye-icon[data-action='show']");
      const $hidePasswordIcon = $togglePasswordContainer.querySelector(".form__eye-icon[data-action='hide']");

      const $formGroup = $togglePasswordContainer.closest(".form__form-group");
      const $passwordField = $formGroup.querySelector(".form__form-control");

      if($passwordField.type === "text") {
        $passwordField.type = "password";
      } else {
        $passwordField.type = "text";
      }

      $showPasswordIcon.classList.toggle("form__eye-icon--active");
      $hidePasswordIcon.classList.toggle("form__eye-icon--active");
    }
  });
})();
