import Vue from "modules/vue_twemoji";
import QuestionSubscription from "components/forum/question-subscription";
import { store } from "store";

export default () => {
  const $container = document.querySelector(".js-notification-subscription");

  if($container && window.state.questionId) {
    new Vue({
      el: $container,
      store,
      render: h => h(QuestionSubscription)
    });
  }
};
