<template>
  <span class="flickering-word">{{ currentWord }}</span>
</template>

<script>
const WORDS = ["product", "marketing", "design", "business"];
const FLICKER_PERIOD = 3500;

export default {
  data() {
    return {
      currentWordIndex: 3
    };
  },
  computed: {
    currentWord() {
      return WORDS[this.currentWordIndex];
    }
  },
  mounted() {
    setInterval(() => {
      this.currentWordIndex = (this.currentWordIndex + 1) % WORDS.length;
    }, FLICKER_PERIOD);
  }
}
</script>

<style lang="scss">
  .flickering-word {
    text-decoration: underline;
    text-decoration-style: dotted;
  }
</style>
