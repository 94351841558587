import Axios from "axios";
import Sentry from "modules/sentry";

(function() {
  const $certificateChapter = document.querySelector(".js-certificate-chapter");

  if(!$certificateChapter) {
    return;
  }

  Axios
    .get("/certificate-chapter.json")
    .then(response => {
      $certificateChapter.innerHTML = response.data.certificate_chapter_html;
    })
    .catch(error => {
      Sentry.captureException(error)
    });
})();
