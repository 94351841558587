<template>
  <DropdownMenu name="Difficutly">
    <DropdownMenuItem
      name="Easy"
      value="difficulty:1" />

    <DropdownMenuItem
      name="Intermediate"
      value="difficulty:2" />

    <DropdownMenuItem
      name="Hard"
      value="difficulty:3" />

    <DropdownMenuItem
      name="Hardcore"
      value="difficulty:4" />
  </DropdownMenu>
</template>

<script>
import DropdownMenu from "./dropdown-menu";
import DropdownMenuItem from "./dropdown-menu-item";

export default {
  name: "PracticeDifficutlyMenu",
  components: {
    DropdownMenu,
    DropdownMenuItem
  }
}
</script>
