import Vue from "vue/dist/vue.esm";
import PageToc from "components/mdn/page-toc";

const $mdnPageContent = document.querySelector(".js-mdn-page-content");
const $mdnPageTocContainers = document.querySelectorAll(".js-mdn-page-toc");

let pageTocs = [];

const initToc = () => {
  const $headlines = $mdnPageContent.querySelectorAll("h2:not(.js-no-toc), h3:not(.js-no-toc)");

  const headlines = []
  $headlines.forEach(h => {
    headlines.push({
      tag: h.tagName.toLowerCase(),
      id: h.id,
      text: h.textContent,
      offsetTop: h.offsetTop
    })
  })

  if (pageTocs.length === 0) {
    pageTocs = Array.from($mdnPageTocContainers).map(el => {
      return new Vue({
        el,
        components: {
          PageToc
        },
        data() {
          return {
            headlines: []
          };
        },
        template: `
          <PageToc
            ref="pageToc"
            :headlines="headlines" />
        `
      });
    });
  }

  pageTocs.forEach(pageToc => {
    pageToc.$refs.pageToc.headlines = headlines;
  });
}

if($mdnPageContent && $mdnPageTocContainers) {
  initToc();

  if(!window.state) {
    window.state = {};
  }
  window.state.initMDNPageToc = initToc;
}
