const customStore = {
  state: {
    activeMenuItem: null,
    searchQuery: null,
    selectedFilters: {},
    exercises: [],
    datasets: []
  },
  setExercises(exercises) {
    this.state.exercises = exercises;
  },
  setDatasets(datasets) {
    this.state.datasets = datasets;
  },
  activateMenuItem(name) {
    this.state.activeMenuItem = name;
  },
  closeMenus() {
    this.state.activeMenuItem = null;
  },
  toggleFilter(vm, name, value) {
    if(this.filterActive(value)) {
      this.removeFilter(vm, value);
    } else {
      this.addFilter(vm, name, value);
    }
  },
  addFilter(vm, name, value) {
    vm.$set(this.state.selectedFilters, value, name);
  },
  removeFilter(vm, value) {
    vm.$delete(this.state.selectedFilters, value);
  },
  filterActive(value) {
    return !!this.state.selectedFilters[value];
  },
  exerciseVisible(exercise) {
    const filterGroups = Object.keys(this.state.selectedFilters).reduce((groups, filter) => {
      const [field, value] = filter.split(":");

      if(groups[field]) {
        groups[field].push(value);
      } else {
        groups[field] = [value];
      }

      return groups;
    }, {});

    let result = true;

    Object.keys(filterGroups).forEach(filterField => {
      const filterValues = filterGroups[filterField];

      if(filterValues.indexOf(exercise[filterField].toString()) === -1) {
        result = false;
      }
    });

    if(this.state.searchQuery &&
      !(exercise.name.toLowerCase().indexOf(this.state.searchQuery.toLowerCase()) > -1) &&
      !(exercise.summary.toLowerCase().indexOf(this.state.searchQuery.toLowerCase()) > -1)) {
      result = false;
    }

    return result;
  },
  reset() {
    this.closeMenus();
    this.state.selectedFilters = {};
    this.state.searchQuery = null;
  },
  clearSearch() {
    this.state.searchQuery = null;
  }
}

export default customStore;
