import Vue from "modules/vue_twemoji";
import ReplyForm from "components/forum/reply-form";
import { store } from "store";

const $container = document.querySelector(".js-forum-question-replies");

if($container) {
  $container.addEventListener("click", (e) => {
    if(!e.target.classList.contains("js-write-forum-reply")) {
      return;
    }

    e.preventDefault();

    let $reply = e.target.closest(".forum-reply");
    let $replyFormContainer = $reply.querySelector(".forum-reply__reply-form");

    if($replyFormContainer.classList.contains("forum-reply__reply-form--active")) {
      $replyFormContainer.classList.remove("forum-reply__reply-form--active")
      $reply.classList.remove("forum-reply--active");
      return;
    } else {
      document.querySelectorAll(".forum-reply--active").forEach($activeReply => {
        $activeReply.classList.remove("forum-reply--active");

        $activeReply.querySelectorAll(".forum-reply__actions--active").forEach($activeActions => {
          $activeActions.classList.remove("forum-reply__actions--active");
        });

        $activeReply.querySelectorAll(".forum-reply__reply-form--active").forEach($activeForm => {
          $activeForm.classList.remove("forum-reply__reply-form--active");
        });
      });

      $replyFormContainer.classList.add("forum-reply__reply-form--active");

      if($replyFormContainer.querySelector(".forum-reply-form")) {
        $reply.classList.add("forum-reply--active");
        return;
      }

      let $tempContainer = document.createElement("div");
      $replyFormContainer.appendChild($tempContainer);

      $reply.classList.add("forum-reply--active");
      $reply.querySelector(".forum-reply__actions").classList.add("forum-reply__actions--active");

      if(!$reply.querySelector(".js-reply-form")) {
        new Vue({
          el: $tempContainer,
          store,
          render: h => h(ReplyForm, {
            props: {
              replyId: $reply.dataset.replyId
            }
          })
        });
      }

    }

  });
}
