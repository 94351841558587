<template>
  <form class="job-ad-form">
    <section
      class="job-ad-form__errors"
      v-if="showErrors">

      <div
        class="job-ad-form__error"
        v-for="(messages, fieldName) in errors"
        :key="fieldName">
        <div class="job-ad-form__error-field">
          {{ fieldNames[fieldName] }}
        </div>
        <div
          class="job-ad-form__error-message"
          v-html="messages.join('<br>')">
        </div>
      </div>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("position_name") }}</label>
      <input v-model="job.position_name" type="text" placeholder="Junior Product Manager">
      <span class="job-ad-form__form-group-hint">
        Specify the seniority level like <strong>Junior Data Analyst</strong> or <strong>Senior Performace Marketer</strong>.
      </span>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("company_name") }}</label>
      <input v-model="job.company_name" type="text" placeholder="Microsoft">
      <span class="job-ad-form__form-group-hint">
        Your company/product name as it's one the website, <strong>without LLC or GmbH</strong>.
      </span>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("company_description") }}</label>
      <TinyMDE
        v-model="job.company_description"
        :toolbar='["bold", "italic", "hr", "ul", "ol"]'
        class="job-ad-form__textarea" />

      <div class="job-ad-form__status-bar">
        <span class="job-ad-form__form-group-hint">
          Your company description in a Tweet.
        </span>

        <span class="job-ad-form__characters-counter">
          {{ charCounter("company_description", 300) }}
        </span>
      </div>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("location") }}</label>
      <input v-model="job.location" type="text" placeholder="Worldwide (relocation)">
      <span class="job-ad-form__form-group-hint">
        Popular options are <strong>Remote</strong>, <strong>Worldwide (relocation)</strong>, <strong>USA</strong>, <strong>EU</strong> or simply type in the name of a city/cities like <strong>New York, Berlin</strong>.
      </span>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("employment") }}</label>

      <div class="job-ad-form__horizontal-select">
        <div
          v-for="(name, type) in contractTypes"
          :key="type"
          class="job-ad-form__horizontal-select-option"
          :class="{
            'job-ad-form__horizontal-select-option--active': type === job.employment
          }"
          @click.prevent="updateSelected('employment', type)">
          {{ name }}
        </div>
      </div>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("annual_salary") }}</label>
      <input v-model="job.annual_salary" type="text" placeholder="80-120K USD">
      <span class="job-ad-form__form-group-hint">
        Specify a number (<strong>$100K+</strong>) or a range like (<strong>80-120K USD</strong>), don't forget to add a currency.
      </span>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("caption") }}</label>
      <TinyMDE
        v-model="job.caption"
        :toolbar='["bold", "italic", "ul", "ol"]'
        class="job-ad-form__textarea" />

      <div class="job-ad-form__status-bar">
        <span class="job-ad-form__form-group-hint">
          Describe this job in a Tweet. For example: <i>"We're looking for a PM to lead our recommendations team. You'll be working with a team of 5, hands on with all our BI tools."</i>
        </span>

        <span class="job-ad-form__characters-counter">
          {{ charCounter("caption", 360) }}
        </span>
      </div>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("description") }}</label>
      <TinyMDE
        v-model="job.description"
        :toolbar='["bold", "italic", "hr", "ul", "ol", "h2"]'
        class="job-ad-form__textarea" />

      <span class="job-ad-form__form-group-hint">
        Describe why are you hiring, what are the upcoming challenges and how your new hire will use data.
      </span>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("application_url") }}</label>
      <input v-model="job.application_url" type="text" placeholder="foobar.com/apply">
      <span class="job-ad-form__form-group-hint">
        Where people can submit their CV? Provide an application page URL or an email.
      </span>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("company_logo_url") }}</label>
      <div
        class="job-ad-form__file-drag-and-drop"
        @dragover.prevent
        @click.prevent="openFileDialog"
        @drop.prevent="dropCompanyLogo">
        CLICK TO UPLOAD OR DRAG & DROP AN IMAGE HERE
      </div>
      <img
        v-if="companyLogoImg"
        :src="companyLogoImg"
        class="job-ad-form__company-logo-preview">
      <img
        v-if="!companyLogoImg && job.company_logo_url"
        :src="job.company_logo_url"
        class="job-ad-form__company-logo-preview">
      <input
        type="file"
        style="display: none;"
        ref="hidden_file_input"
        @change.prevent="dropCompanyLogo" />
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("company_website_url") }}</label>
      <input v-model="job.company_website_url" type="text" placeholder="https://foobar.com">
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("company_email") }}</label>
      <input v-model="job.company_email" type="text" placeholder="foo@bar.com">
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("reimburse_sql_habit") }}</label>
      <div class="job-ad-form__horizontal-select">
        <div
          v-for="(name, type) in reimburseSqlHabit"
          :key="type"
          class="job-ad-form__horizontal-select-option"
          :class="{
            'job-ad-form__horizontal-select-option--active': type.toString() === job.reimburse_sql_habit.toString()
          }"
          @click.prevent="updateSelected('reimburse_sql_habit', type)">
          {{ name }}
        </div>
      </div>
      <span class="job-ad-form__form-group-hint">
        Offer reimbursment of the SQL Habit course to a successful hire.
      </span>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("remote_friendly") }}</label>
      <div class="job-ad-form__horizontal-select">
        <div
          v-for="(name, type) in remoteFriendly"
          :key="type"
          class="job-ad-form__horizontal-select-option"
          :class="{
            'job-ad-form__horizontal-select-option--active': type.toString() === job.remote_friendly.toString()
          }"
          @click.prevent="updateSelected('remote_friendly', type)">
          {{ name }}
        </div>
      </div>
      <span class="job-ad-form__form-group-hint">
        Are your hires allowed to work at least one day a week from home?
      </span>
    </section>

    <section class="job-ad-form__form-group">
      <label>{{ fieldLabel("data_stack") }}</label>
      <TinyMDE
        v-model="job.data_stack"
        class="job-ad-form__textarea" />

      <span class="job-ad-form__form-group-hint">
        Describe technologies used to handle data at your company. How your future hire will be using them?
      </span>
    </section>

    <section class="job-ad-form__form-group">
      <input
        type="submit"
        class="job-ad-form__cta btn"
        :value="submitValue"
        @click.prevent="submitForm">
    </section>

    <div
      v-if="error"
      class="job-ad-form__generic-error">
      Something went wrong, please contact support at <strong>support@sqlhabit.com</strong>
    </div>
  </form>
</template>

<script>
import TinyMDE from "components/tiny-mde";
import axios from "axios";
import { notify } from "modules/notify";
import currency from "currency.js";
import Sentry from "modules/sentry";

const currencyFormatter = {
  usd: (value) => {
    return currency(value, {
      symbol: "$",
      precision: 2,
      fromCents: true,
      separator: ",",
      decimal: ".",
      pattern: "!#"
    }).format().replace(".00", "");
  },
  eur: (value) => {
    return currency(value, {
      symbol: "€",
      precision: 2,
      fromCents: true,
      separator: ".",
      decimal: ",",
      pattern: "#!"
    }).format().replace(",00", "");
  }
}

export default {
  components: {
    TinyMDE,
  },
  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      error: null,
      errors: {},
      contractTypes: {
        "full-time": "Full-time",
        "part-time": "Part-time"
      },
      reimburseSqlHabit: {
        true: "Yep",
        false: "Nope"
      },
      remoteFriendly: {
        true: "Yep",
        false: "Nope"
      },
      job: window.state.job,
      requiredFields: window.state.jobRequiredFields,
      companyLogoFile: null,
      companyLogoImg: null,
      fieldNames: {
        company_name: "COMPANY NAME",
        position_name: "POSITION NAME",
        location: "LOCATION",
        annual_salary: "ANNUAL SALARY",
        description: "JOB DESCRIPTION",
        application_url: "APPLICATION LINK",
        company_email: "COMPANY EMAIL",
        company_website_url: "COMPANY WEBSITE",
        reimburse_sql_habit: "REIMBURSE SQL HABIT",
        data_stack: "DATA STACK",
        employment: "CONTRACT TYPE",
        remote_friendly: "REMOTE FRIENDLY",
        caption: "CAPTION",
        company_description: "COMPANY DESCRIPTION",
        company_logo_url: "COMPANY LOGO",
        company_logo: "COMPANY LOGO"
      },
      charCounters: {
        caption: null,
        company_description: null
      }
    }
  },
  computed: {
    showErrors() {
      return Object.keys(this.errors).length > 0;
    },
    submitValue() {
      return this.job.slug ? "UPDATE JOB AD" : "CREATE JOB AD";
    }
  },
  methods: {
    charCounter(field, maxCharacters) {
      let currentLength = 0

      if(this.job[field]) {
        currentLength = this.job[field].length;
      }

      return `${currentLength} / ${maxCharacters}`;
    },
    fieldLabel(name) {
      let label = this.fieldNames[name];

      if(this.requiredFields.indexOf(name) > -1) {
        label = label.concat("*")
      }

      return label;
    },
    dropCompanyLogo(event) {
      let droppedFiles = (event.dataTransfer && event.dataTransfer.files) || event.target.files;
      if(!droppedFiles) return;

      let imageFile = droppedFiles[0];
      this.companyLogoFile = imageFile;

      let reader = new FileReader();
      reader.onload = (e) => {
        this.companyLogoImg = e.target.result;
      }
      reader.readAsDataURL(imageFile);
    },
    updateSelected(field, value) {
      this.job[field] = value;
    },
    openFileDialog() {
      this.$refs.hidden_file_input.click();
    },
    stringifyInputValue(value) {
      return value === null ? "" : value;
    },
    submitForm() {
      let formData = new FormData();

      if(this.job.slug) {
        formData.append("id", this.job.slug);
      }

      formData.append("job[position_name]", this.stringifyInputValue(this.job.position_name));
      formData.append("job[company_name]", this.stringifyInputValue(this.job.company_name));
      formData.append("job[company_website_url]", this.stringifyInputValue(this.job.company_website_url));
      formData.append("job[company_email]", this.stringifyInputValue(this.job.company_email));
      formData.append("job[annual_salary]", this.stringifyInputValue(this.job.annual_salary));
      formData.append("job[location]", this.stringifyInputValue(this.job.location));
      formData.append("job[caption]", this.stringifyInputValue(this.job.caption));
      formData.append("job[employment]", this.stringifyInputValue(this.job.employment));
      formData.append("job[company_description]", this.stringifyInputValue(this.job.company_description));
      formData.append("job[data_stack]", this.stringifyInputValue(this.job.data_stack));
      formData.append("job[description]", this.stringifyInputValue(this.job.description));
      formData.append("job[application_url]", this.stringifyInputValue(this.job.application_url));
      formData.append('job[reimburse_sql_habit]', this.stringifyInputValue(this.job.reimburse_sql_habit));
      formData.append('job[remote_friendly]', this.stringifyInputValue(this.job.remote_friendly));

      if(this.companyLogoFile) {
        formData.append('job[company_logo]', this.companyLogoFile);
      }

      axios({
        method: this.job.slug ? "put" : "post",
        url: this.job.slug ? `/job-board/jobs/${this.job.slug}` : "/job-board/jobs",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        notify({
          message: this.job.slug ? "Job ad was succssfully updated" : "Job ad was created"
        });

        window.location = response.data.redirect_to;
      }).catch((error) => {
        const data = error.response.data || {};

        if(data.error) {
          this.error = data.error;
        }

        if(data.errors) {
          this.errors = data.errors;
        }

        notify({
          message: "Something went wrong, please contact support at support@sqlhabit.com",
          type: "alert"
        });

        Sentry.captureException(error);

        window.scrollTo(0, 0);
      });
    }
  }
}
</script>

<style lang="scss">
.job-ad-form {
  &__errors {
    margin-bottom: $px24;
  }

  &__form-group {
    display: flex;
    flex-direction: column;
    position: relative;

    & + & {
      margin-top: $px32;
    }

    .CodeMirror,
    .CodeMirror-scroll {
      height: auto !important;
      min-height: $px64 !important;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  textarea {
    width: 100%;
    padding: $px8;

    border: $px1 solid $grey-8;
    border-radius: $px4;
    outline: none;

    @include font-regular;

    appearance: none;

    &::placeholder {
      @include font-regular;
      color: $grey-7;
    }

    &.error {
      border-color: $red-5;
    }
  }

  select {
    min-width: $px240;
    padding: $px8 $px16;

    border: $px2 solid $grey-7;
    border-radius: $px4;

    outline: none;
  }

  textarea {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  label {
    margin-bottom: $px2;

    @include font-label;
  }

  &__form-group-error {
    margin-top: $px8;

    @include font-small;
    color: $red-5;
  }

  &__form-group-hint {
    margin-top: $px8;

    @include font-small;
    color: $grey-6;
  }

  &__status-bar {
    display: flex;
    justify-content: space-between;
  }

  &__characters-counter {
    margin-top: $px8;
    margin-left: $px24;

    @include font-small;
    color: $grey-6;
    white-space: nowrap;
  }

  &__submit {
    margin-top: $px8;

    outline: none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__toggle-password {
    position: absolute;
    top: 50%;
    right: $px8;
  }

  &__eye-icon {
    width: $px24;
    height: $px24;
    display: none;

    &--active {
      display: block;
    }
  }

  &__horizontal-select {
    display: flex;
  }

  &__horizontal-select-option {
    padding: $px8;

    background-color: $grey-10;

    cursor: pointer;

    &:first-child {
      border-radius: $px8 0 0 $px8;
    }

    &:last-child {
      border-radius: 0 $px8 $px8 0;
    }

    &--active {
      background-color: $grey-8;

      cursor: auto;
    }

    sup {
      margin-left: $px2;
      padding: $px2 $px4;

      border-radius: $px4;

      background-color: $red-5;

      @include font-legal;
      color: $white;
    }
  }

  &__file-drag-and-drop {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $px96;
    border-radius: $px8;

    background-color: $grey-9;

    color: $grey-5;

    cursor: pointer;
  }

  &__company-logo-preview {
    width: $px64;
    height: $px64;
    margin-top: $px8;

    border-radius: $px8;
  }

  &__generic-error {
    padding: $px16;
    margin-top: $px24;

    border-radius: $px8;

    background-color: $red-9;
  }

  &__errors {
    padding: $px16;
    margin-top: $px24;

    border-radius: $px8;

    background-color: $red-9;
  }

  &__error {
    &:not(:last-child) {
      margin-bottom: $px24;
    }
  }

  &__error-field {
    margin-bottom: $px2;

    @include font-label;
  }

  &__error-message {
    @include font-small;
  }
}
</style>
