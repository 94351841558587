<template>
  <div
    class="search-popup">
    <div class="search-popup__container">
      <div
        ref="body"
        class="search-popup__body">
        <div class="search-popup__search-field">
          <SearchField
            :searchIndex="searchIndex"
            @close="close"
            @searchResults="updateSearchResults" />
        </div>

        <div class="search-popup__search-results">
          <SearchResults
            :query="query"
            :results="results" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchField from "components/search/search-field";
import SearchResults from "components/search/search-results";

export default {
  name: "SearchPopup",
  components: {
    SearchField,
    SearchResults
  },
  props: {
    searchIndex: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      results: [],
      query: null
    }
  },
  methods: {
    close() {
      this.$destroy();

      // remove the element from the DOM
      if(this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    },
    updateSearchResults(newResults, query) {
      this.results = newResults;
      this.query = query;
    }
  },
  mounted() {
    const $body = document.querySelector("body");
    $body.classList.add("no-scroll");

    document.addEventListener("keydown", e => {
      if(e.key !== "Escape" && e.key !== "Esc") {
        return;
      }

      this.close();
    });

    document.addEventListener("click", e => {
      let targetElement = e.target;

      if(targetElement.classList && targetElement.classList.contains("js-navbar-search-icon")) {
        return;
      }

      const $popupBody = this.$refs.body;

      if(!$popupBody) {
        return;
      }

      do {
        if (targetElement == $popupBody) {
          return;
        }

        if(targetElement.classList && targetElement.classList.contains("search-popup__body")) {
          return;
        }

        targetElement = targetElement.parentNode;
      } while (targetElement);

      e.preventDefault();

      this.close();
    });
  },
  destroyed() {
    const $body = document.querySelector("body");
    $body.classList.remove("no-scroll");
  }
}
</script>

<style lang="scss">
.search-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483650;
  display: flex;

  background-color: rgba($color: $grey-3, $alpha: 0.7);

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;

    transition: all .25s;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: $px720;
    margin-top: $px72;

    border-radius: $px8;

    background-color: $grey-10;
  }
}
</style>
