import Vue from "vue/dist/vue.esm";
import ContinueBanner from "components/continue-banner";

Vue.directive("visible", function(el, binding) {
	el.style.visibility = !!binding.value ? "visible" : "hidden";
});

const $continueBannerContainer = document.querySelector(".js-continue-banner");

const mountContinueBanner = () => {
  if($continueBannerContainer) {
    const { userContentItems } = $continueBannerContainer.dataset;

    new Vue({
      el: $continueBannerContainer,
      render: h => h(ContinueBanner, {
        props: {
          userContentItems: parseInt(userContentItems, 10)
        }
      })
    });
  }
}

export default mountContinueBanner;
