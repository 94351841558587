document.querySelectorAll(".js-share-on-twitter").forEach(el => {
  el.addEventListener("click", e => {
    e.preventDefault();

    let url = "http://www.twitter.com/intent/tweet";

    if(typeof(pageUrl) === "undefined") {
      const ogUrl = document.querySelector("meta[property='og:url']").content;
      const ogTitle = document.querySelector("meta[property='og:title']").content;

      url += "?url=" + encodeURIComponent(ogUrl);
      url += "&text=" +encodeURIComponent(ogTitle);
    } else {
      url += "?url=" + encodeURIComponent(pageUrl);
      url += "&text=" +encodeURIComponent(pageTitle);
    }

    const left = (screen.width - 550) / 2;
    const top = (screen.height - 285) / 3;

    window.open(
      url,
      "Twitter",
      `toolbar=no,top=${top},left=${left},status=no,menubar=no,scrollbars=yes,width=550,height=285,resizable=1`
    );
  });
});
