import Vue from "modules/vue_twemoji";
import VueI18n from "vue-i18n";
import ContentItemRating from "components/content-item-rating.vue";
import { store } from "store";

export default () => {
  const $ratingContainer = document.querySelector("#content-item-rating");

  if($ratingContainer) {
    const {
      ratingId,
      ratingValue,
      ratingType,
      ratingPersisted,
      feedbackPresent,
      createUrl
    } = $ratingContainer.dataset;

    new Vue({
      name: "ContentItemRating",
      el: $ratingContainer,
      store,
      i18n: new VueI18n({
        locale: "en"
      }),
      render: h => h(ContentItemRating, {
        props: {
          inputRating: {
            id: ratingId ? ratingId : null,
            value: ratingValue ? parseInt(ratingValue, 10) : null,
            type: ratingType ? ratingType : "thumbs",
            createUrl,
            persisted: ratingPersisted === "true",
            feedbackPresent: feedbackPresent === "true",
            addFeedbackUrl: (ratingId) => {
              return `/content_item_ratings/${ratingId}/add_feedback.json`
            }
          }
        }
      })
    });
  }
};
