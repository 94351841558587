import Vue from "modules/vue_twemoji";
import JobAdOwnerMenu from "components/job-board/job-ad-owner-menu";
import axios from "axios";

const $container = document.querySelector(".js-job-ad-owner-menu");

if($container) {
  const slug = $container.dataset.slug;

  axios.get(`/api/job_board/jobs/${slug}/owner_menu.json`, {}).then(response => {
    if(response.data.show_owner_menu) {
      new Vue({
        el: $container,
        render: h => h(JobAdOwnerMenu, {
          props: {
            state: response.data.state,
            slug: slug,
            publishable: response.data.publishable,
            purchaseUrl: response.data.purchaseUrl,
            approved: response.data.approved
          }
        })
      });
    }
  }).catch(function(error) {
    Sentry.captureException(error);
  });
}
