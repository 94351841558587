import Vue from "vue/dist/vue.esm";
import Tooltip from "components/tooltip.vue";

document.querySelectorAll(".js-tooltip").forEach($el => {
  const copy = $el.dataset.copy;

  new Vue({
    el: $el,
    render: h => h(Tooltip, {
      props: {
        copy: copy
      }
    })
  });
});
