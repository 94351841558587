import state from "./exercise/state";
import getters from "./exercise/getters";
import mutations from "./exercise/mutations";
import actions from "./exercise/actions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
