import Vue from "vue/dist/vue.esm"
import FlickeringWord from "components/flickering-word.vue";

const $container = document.querySelector(".js-hero-section-word");

if($container) {
  new Vue({
    el: $container,
    render: h => h(FlickeringWord)
  });
}
