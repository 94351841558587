export default (userSegment) => {
  if(userSegment === "customer") {
    document.querySelectorAll("a[href^='/lessons/']").forEach($lessonLink => {
      $lessonLink.href = "/content" + $lessonLink.getAttribute("href");
    });

    document.querySelectorAll("a[href^='/exercises/']").forEach($exerciseLink => {
      $exerciseLink.href = "/content" + $exerciseLink.getAttribute("href");
    });
  }
}
