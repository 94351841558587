import Vue from "vue/dist/vue.esm";
import VueI18n from "vue-i18n";
import Countdown from "components/countdown";

export const mountCountdown = () => {
  const $coundownConfig = document.querySelector(".js-sale-countdown-config");
  const $coundownContainer = document.querySelector(".js-sale-countdown-container");

  if(!($coundownContainer && $coundownConfig)) {
    return;
  }

  let { endDate, modifiers } = $coundownConfig.dataset;

  modifiers = (modifiers || "").split(",");

  new Vue({
    el: $coundownContainer,
    i18n: new VueI18n({
      locale: "en",
      silentTranslationWarn: process.env.NODE_ENV === "production"
    }),
    render: h => h(Countdown, {
      props: {
        endDateInput: endDate,
        modifiers: modifiers
      }
    })
  });
}
