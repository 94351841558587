(function() {
  const header = document.querySelector(".js-sticky-mobile-header");

  if(!header) {
    return;
  }

  if(screen.width > 920) {
    return;
  }

  const headerContainer = document.querySelector(".application-layout__header");
  const headerOffset = header.offsetTop;

  function handleSticky() {
    if (window.scrollY > headerOffset) {
      header.classList.add("mobile-navbar--sticky");
      headerContainer.classList.add("application-layout__header--sticky");
    } else {
      header.classList.remove("mobile-navbar--sticky");
      headerContainer.classList.remove("application-layout__header--sticky");
    }
  }

  window.addEventListener("scroll", () => {
    handleSticky();
  });

  handleSticky();
})();
