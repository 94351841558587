import Vue from "modules/vue_twemoji";
import ReplyForm from "components/forum/reply-form";
import { store } from "store";

const $container = document.querySelector(".js-partial-forum-reply-form");

if($container) {
  new Vue({
    name: "ReplyForm",
    el: $container,
    store,
    render: h => h(ReplyForm, {
      props: {
      }
    })
  });
}
