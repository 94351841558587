import Vue from "modules/vue_twemoji";
import BookmarkTable from "components/bookmark-table";

const $container = document.querySelector(".js-bookmark-table");

if($container) {
  new Vue({
    el: $container,
    render: h => h(BookmarkTable, {
      props: {
      }
    })
  });
}
