const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add("chart-js-tooltip");

    const tooltipContainer = document.createElement('div');
    tooltipContainer.classList.add("chart-js-tooltip__container");

    let tooltipArrow = document.createElement('div');
    tooltipArrow.classList.add("chart-js-tooltip__arrow");

    tooltipEl.appendChild(tooltipContainer);
    tooltipEl.appendChild(tooltipArrow);

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const colors = tooltip.labelColors[0];

    let seriesName;
    let dataPointX;
    let dataPointY;

    if(tooltip.dataPoints[0].element.constructor.name === "ArcElement") {
      seriesName = tooltip.dataPoints[0].label;
      dataPointY = tooltip.dataPoints[0].raw;
    } else {
      seriesName = tooltip.dataPoints[0].dataset.label;
      dataPointX = tooltip.dataPoints[0].raw.x;
      dataPointY = tooltip.dataPoints[0].raw.y;
    }

    const tooltipBodyHtml = `
    <div class="chart-js-tooltip__body">
      <div class="chart-js-tooltip__y">
        ${dataPointY}
      </div>
      ${typeof(dataPointX) === "undefined" ? "" : `<div class="chart-js-tooltip__x">
        ${dataPointX}
      </div>`}
      ${seriesName === "default" ? "" : `<div class="chart-js-tooltip__series">
        <div
          style="background-color: ${colors.backgroundColor};"
          class="chart-js-tooltip__series-color"></div>
        ${seriesName}
      </div>`}
    </div>
    `;

    const tooltipBody = document.createElement("div");
    tooltipBody.classList.add("chart-js-tooltip__body");
    tooltipBody.innerHTML = tooltipBodyHtml;

    const tooltipContainer = tooltipEl.querySelector(".chart-js-tooltip__container");

    // Remove old children
    while (tooltipContainer.firstChild) {
      tooltipContainer.firstChild.remove();
    }

    // Add new children
    tooltipContainer.appendChild(tooltipBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;

  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY - 16 + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export default externalTooltipHandler;
