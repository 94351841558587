<template>
  <div class="js-tiny-mde-container">
    <div
      class="js-tiny-mde-toolbar"
      :data-commands="toolbar" />

    <textarea
      class="js-tiny-mde"
      :data-image-drop="imageDropEnabled"
      ref="textarea" />
  </div>
</template>

<script>
import { initTinyMDE } from "components/markdown-editor";
import "tiny-markdown-editor/dist/tiny-mde.min.css";

export default {
  name: "TinyMDE",
  data() {
    return {
      text: null,
      editor: null
    }
  },
  props: {
    toolbar: {
      type: Array,
      required: false,
      default: () => ["bold", "italic", "hr", "insertPgsql", "ul", "ol", "h2"]
    },
    imageDropEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.editor = initTinyMDE(this.$refs.textarea);

    if (this.value) {
      this.editor.paste(this.value);
    }

    this.editor.addEventListener("change", event => {
      this.$emit("input", event.content);
    })
  }
}
</script>
