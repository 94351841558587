import Vue from "vue/dist/vue.esm";
import WelcomeChecklist from "components/welcome-checklist/checklist";
import mountContinueBanner from "controllers/continue-banner";
import axios from "axios";
import Sentry from "modules/sentry";

const $container = document.querySelector(".contents-page__welcome-checklist");
const $el = document.querySelector("#welcome-checklist");

if($container && $el) {
  axios.get("/api/welcome_checklists/state")
    .then(response => {
      if(response.data.show) {
        new Vue({
          el: $el,
          render: h => h(WelcomeChecklist, {
            props: {
              state: response.data.state
            }
          })
        });

        $container.classList.add("contents-page__welcome-checklist--active");
      } else {
        mountContinueBanner();
      }
    }).catch(error => {
      Sentry.captureException(error);
    });
}
