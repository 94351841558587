import Vue from "modules/vue_twemoji";
import PracticeExercises from "components/practice/exercises";
import customStore from "components/practice/custom-store";
import { store } from "store";

const $container = document.querySelector(".js-mount-practice-exercises");

if($container) {
  const datasets = JSON.parse($container.dataset.datasets);

  customStore.setDatasets(datasets);

  new Vue({
    el: $container,
    render: h => h(PracticeExercises),
    store
  });

  document.addEventListener("keydown", e => {
    if(e.key !== "Escape" && e.key !== "Esc") {
      return;
    }

    customStore.closeMenus();
  });
}
