export const nextSiblingWithClass = (el, className) => {
  if(!el) {
    return null;
  }

	let sibling = el.nextElementSibling;

	while (sibling) {
		if (sibling.matches(className)) {
      return sibling;
    }

    sibling = sibling.nextElementSibling;
  }

  return null;
};
