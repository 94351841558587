import Vue from "vue/dist/vue.esm";
import ScreenshotGallery from "components/screenshot-gallery";

const $galleryContainer = document.querySelector("#screenshots-gallery");

if($galleryContainer) {
  new Vue({
    el: $galleryContainer,
    render: h => h(ScreenshotGallery)
  });
}
