import Vue from "modules/vue_twemoji";
import Vuex from "vuex";

import user from "store/user";
import chart from "store/chart";
import lesson from "store/lesson";
import exercise from "store/exercise";

Vue.use(Vuex);

const modules = {
  user,
  chart,
  lesson,
  exercise
};

const store = new Vuex.Store({ modules });

export { store }
