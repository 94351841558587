const $deleteAccountLink = document.querySelector(".js-delete-account");

if($deleteAccountLink) {
  $deleteAccountLink.addEventListener("click", e => {
    var userInput = prompt("Please type DELETE to delete your account:");

    if(userInput !== "DELETE") {
      e.preventDefault();
      e.stopPropagation();
    }
  });
}
