import { render, staticRenderFns } from "./difficutly-menu-item.vue?vue&type=template&id=382c9ba9"
import script from "./difficutly-menu-item.vue?vue&type=script&lang=js"
export * from "./difficutly-menu-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports