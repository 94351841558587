<template>
  <a
    class="welcome-checklist-item"
    :href="link">

    <div
      class="welcome-checklist-item__index"
      :class="{
        'welcome-checklist-item__index--finished': finished
      }">
      {{ index }}
    </div>

    <div class="welcome-checklist-item__body">
      <h4 class="welcome-checklist-item__name">
        {{ name }}
      </h4>

      <div
        class="welcome-checklist-item__description"
        v-html="description" />
    </div>
  </a>
</template>

<script>
export default {
  name: "WelcomeChecklistItem",
  props: ["index", "name", "description", "link", "finished"]
}
</script>

<style lang="scss">
.welcome-checklist-item {
  display: flex;
  align-items: center;

  text-decoration: none;

  &__index {
    width: $px32;
    height: $px32;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: $px12;

    border-radius: 50%;

    background-color: $grey-8;

    @include h4;
    color: $white;

    &--finished {
      background-color: $blue-6;

      color: $white;
    }
  }

  &__name {
    margin: 0;
    padding: 0;
  }
}
</style>
