<template>
  <transition name="fade">
    <div
      class="modal modal--active"
      v-if="active">

      <div
        class="auth-popup"
        :class="{
          'auth-popup--square-illustration': signupFormActive,
          'auth-popup--circle-illustration': !signupFormActive
        }">
        <div class="auth-popup__container">
          <CloseIcon :class="'auth-popup__close-icon'" @click="close" />

          <div
            class="auth-popup__body"
            v-if="signupFormActive">

            <MdnSignupPopup
              v-if="signupPopup === 'mdn-signup-popup'"
              :afterAuth="afterAuth"
              @toggleForm="toggleForm" />
            <SignupPopup
              v-else
              :afterAuth="afterAuth"
              @toggleForm="toggleForm" />
          </div>

          <div
            class="auth-popup__body"
            v-else>
            <LoginPopup
              :afterAuth="afterAuth"
              @toggleForm="toggleForm" />
          </div>

          <div class="auth-popup__tos">
            By continuing you indicate that you've read and agree to the <a href="/terms">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseIcon from "@images/font_awesome/x-light.svg";
import SignupPopup from "components/signup-popup";
import MdnSignupPopup from "components/mdn-signup-popup";
import LoginPopup from "components/login-popup";

export default {
  components: {
    CloseIcon,
    SignupPopup,
    MdnSignupPopup,
    LoginPopup
  },
  data() {
    return {
      active: false,
      signupFormActive: true,
      afterAuth: null,
      signupPopup: "signup-popup"
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    afterAuthProp: {
      type: String,
      default: null
    },
    signupFormActiveProp: {
      type: Boolean,
      default: true
    },
    signupPopupType: {
      type: String,
      default: "signup-popup"
    }
  },
  methods: {
    close() {
      this.active = false;
    },
    show(afterAuth = null, signupFormActive = true, signupPopup = "signup-popup") {
      this.active = true;
      this.afterAuth = afterAuth;
      this.signupFormActive = signupFormActive;
      this.signupPopup = signupPopup
    },
    hide() {
      this.active = false;
      this.afterAuth = null;
    },
    isVisible() {
      return this.active;
    },
    toggleForm() {
      this.signupFormActive = !this.signupFormActive;
    }
  },
  mounted() {
    this.active = this.visible;
    this.afterAuth = this.afterAuthProp;
    this.signupFormActive = this.signupFormActiveProp;
    this.signupPopup = this.signupPopupType;
  }
}
</script>

<style lang="scss">
.auth-popup {
  position: relative;
  padding: $px48 $px24 $px16;
  overflow: hidden;

  border-radius: $px4;

  background-color: $white;

  &--square-illustration {
    &:after {
      display: none;
      position: absolute;
      z-index: 2;
      width: 350px;
      height: 350px;
      top: -75px;
      left: -50px;

      border-radius: $px16;

      background-color: $orange-10;
      opacity: 0.8;

      content: "";
      transform: rotate(80deg);

      @include breakpoint($md) {
        display: block;
      }
    }
  }

  &--circle-illustration {
    &:after {
      display: none;
      position: absolute;
      z-index: 2;
      width: 350px;
      height: 350px;
      top: -75px;
      left: -50px;

      border-radius: 175px;

      background-color: $blue-10;
      opacity: 0.8;

      content: "";
      transform: rotate(80deg);

      @include breakpoint($md) {
        display: block;
      }
    }
  }

  &__close-icon {
    position: absolute;
    top: $px8;
    right: $px8;
    width: $px24;
    height: $px24;
    z-index: 2;

    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__body {
    position: relative;
    z-index: 3;
  }

  &__tos {
    margin-top: $px24;

    @include font-legal;

    a {
      @include font-legal;
    }
  }
}
</style>
