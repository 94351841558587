(function() {
  const $container = document.querySelector(".js-grab-to-scroll");

  if(!$container) {
    return;
  }

  let isDown = false;
  let startX;
  let scrollLeft;

  $container.style.cursor = "grab";

  $container.addEventListener("mousedown", e => {
    $container.style.cursor = "grabbing";

    isDown = true;

    startX = e.pageX - $container.offsetLeft;
    scrollLeft = $container.scrollLeft;
  });

  $container.addEventListener("mouseleave", () => {
    isDown = false;

    $container.style.cursor = "grab";
  });

  $container.addEventListener("mouseup", () => {
    isDown = false;

    $container.style.cursor = "grab";
  });

  $container.addEventListener("mousemove", e => {
    if(!isDown) {
      return;
    }

    e.preventDefault();

    const x = e.pageX - $container.offsetLeft;
    const walk = x - startX;

    $container.scrollLeft = scrollLeft - walk;
  });
})();
