import { getCookie, setCookie } from "modules/cookie";

(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ref = urlParams.get("ref");

  if(ref && ref.length < 255) {
    setCookie("ref", ref, 30);
  }
})();
