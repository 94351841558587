(function() {
  const $blogPostPage = document.querySelector(".js-blog-post-page");

  if(!$blogPostPage) {
    return;
  }

  const $viewCommentsLink = $blogPostPage.querySelector(".js-show-disqus-comments");
  const $commentsSection = $blogPostPage.querySelector(".blog-post-page__comments");

  $viewCommentsLink.addEventListener("click", e => {
    e.preventDefault();

    $commentsSection.classList.add("blog-post-page__comments--active");

    (function() {
      var d = document, s = d.createElement('script');
      s.src = 'https://sql-habit-blog.disqus.com/embed.js';
      s.setAttribute('data-timestamp', +new Date());
      (d.head || d.body).appendChild(s);
    })();
  });
})();
