import Vue from "vue/dist/vue.esm";

import ContentItemRating from "components/content-item-rating.vue";

const $ratingContainer = document.querySelector("#auto-mount-content-item-rating");

if($ratingContainer) {
  new Vue({
    el: $ratingContainer,
    render: h => h(ContentItemRating)
  });
}
