<template>
  <div class="job-ad-banner">
    <a class="job-ad-banner__link-wrapper" :href="job.url" target="_blank">
      <div class="job-ad-banner__position">
        <img
          v-if="job.company_logo_url"
          :src="job.company_logo_url"
          class="job-ad-banner__company-logo">

        <div class="job-ad-banner__info">
          <div class="job-ad-banner__company-name">
            {{ job.company_name }}
          </div>

          <div class="job-ad-banner__position-name">
            <strong>{{ job.position_name }}</strong>
          </div>
        </div>
      </div>

      <div
        class="job-ad-banner__caption"
        v-html="job.caption" />

      <div class="job-ad-banner__tags">
        <div class="job-ad-banner__tag">
          <PinIcon class="job-ad-banner__tag-icon" />
          {{ job.location }}
        </div>

        <div
          class="job-ad-banner__tag"
          v-if="job.remote_friendly">
          <LaptopIcon class="job-ad-banner__tag-icon" />
          Remote friendly
        </div>

        <div class="job-ad-banner__tag">
          <BriefcaseIcon class="job-ad-banner__tag-icon" />
          {{ job.employment }}
        </div>

        <div
          v-if="job.annual_salary"
          class="job-ad-banner__tag">
          <SalaryIcon class="job-ad-banner__tag-icon" />
          {{ job.annual_salary }}
        </div>
      </div>
    </a>

    <div class="job-ad-banner__settings-bar">
      <div class="job-ad-banner__settings-bar-item">
        JOB AD
      </div>

      <a
        href="#"
        target="_blank"
        class="job-ad-banner__settings-bar-item"
        @click.prevent="hideJobAds">
        Hide job ads
      </a>
    </div>
  </div>

</template>

<script>
import PinIcon from "@images/font_awesome/map-marker-alt-light.svg";
import LaptopIcon from "@images/font_awesome/laptop-house-light.svg";
import BriefcaseIcon from "@images/font_awesome/briefcase-light.svg";
import SalaryIcon from "@images/font_awesome/money-bill-wave-light.svg";
import { setCookie } from "modules/cookie";

export default {
  components: {
    PinIcon,
    LaptopIcon,
    BriefcaseIcon,
    SalaryIcon
  },
  props: {
    job: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    hideJobAds: function () {
      setCookie("disable-job-ads", true, 30);

      this.$destroy();

      this.$el.parentNode.removeChild(this.$el);
    }
  }
}
</script>

<style lang="scss">
.job-ad-banner {
  &__link-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $px16 $px24;
    max-width: $px720;
    position: relative;

    border: 0;
    border-radius: $px4;

    background-color: $grey-10;

    text-decoration: none;

    transition: all 0.25s ease-in;

    &:before {
      display: block;
      width: $px8;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      border-radius: $px8 0 0 $px8;

      background-color: $purple-7;

      content: "";
    }

    &:hover {
      transform: scale(1.01);
    }
  }

  &__position {
    display: flex;
    align-items: center;
    margin-bottom: $px8;
  }

  &__caption {
    margin-bottom: $px16;
  }

  &__company-logo {
    width: $px64;
    height: $px64;
    margin-right: $px8;

    border-radius: $px8;
  }

  &__position-name {
    margin-bottom: $px4;

    @include font-caption;
  }

  &__company-name {
    @include font-small;
    color: $grey-3;
  }

  &__tags {
    display: flex;
    gap: $px4;
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: $px4;

    background-color: $grey-10;

    @include font-micro;

    &:not(:last-child) {
      margin-right: $px24;
    }
  }

  &__tag-icon {
    width: $px16;
    height: $px16;
    margin-right: $px4;
  }

  &__settings-bar {
    display: flex;
    margin-top: $px4;

    @include font-legal;
  }

  &__settings-bar-item {
    &:not(:last-child) {
      margin-right: $px8;
    }
  }
}
</style>
