import axios from "axios";
import mountBookmarks from "controllers/bookmark";
import mountMarkAsFinished from "controllers/mark-as-finished";
import mountContentItemRating from "controllers/content-item-rating";
import updateFullContentLinks from "modules/update-full-content-links";
import { mountExerciseSolvedCheckmark } from "modules/content-item-solved-check-mark";
import mountExerciseForm from "components/exercise-form";
import mountHintAndSolutionLinks from "components/exercise";
import { mountContentItemRunQueryLinks } from "modules/playground-link-for-queries";
import { mountMathjax } from "controllers/mathjax";
import { highlightAll } from "modules/code-highlight";
import { store } from "store";

const loadExerciseContentPartial = ($exerciseContent, userSegment) => {
  const { slug } = $exerciseContent.dataset;

  const params = {
    slug
  };

  axios.get("/api/partials/full-exercise-content.json", {
    params: params
  }).then(response => {
    const params = response.data;

    if(params.partial) {
      $exerciseContent.outerHTML = params.partial;

      store.dispatch("exercise/setSlug", slug);
      store.dispatch("exercise/setCompletedFlag", params.exercise_completed);

      mountExerciseForm();
      mountHintAndSolutionLinks();
      mountBookmarks();
      mountMarkAsFinished();
      mountContentItemRating();
      highlightAll();
      mountMathjax();
      updateFullContentLinks(userSegment);
      mountContentItemRunQueryLinks();
      mountExerciseSolvedCheckmark(params.exercise_completed);
    }
  });
}

export default loadExerciseContentPartial;
