<template>
  <div class="team-size-calculator">
    <div class="team-size-calculator__prices">
      <div class="team-size-calculator__original-price" v-if="showOriginalPrice">
        {{ originalPrice }}
      </div>

      <div class="team-size-calculator__final-price">
        {{ finalPrice }}
      </div>
    </div>

    <div class="form team-size-calculator__form">
      <div class="form__form-group team-size-calculator__size-input">
        <label for="">Team size</label>
        <input
          type="number"
          placeholder="42"
          v-model.number="teamSize"
          :min="minTeamSize"
          @input="updateTeamSize">
      </div>

      <a
        :href="purchaseHref"
        class="btn team-size-calculator__cta"
        :class="{
          'js-open-signup-popup': isGuest
        }"
        :data-after-auth="purchaseHref">
        {{ ctaCopy }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import currency from "currency.js";

const MIN_TEAM_SIZE = 1;

const currencyFormatter = {
  usd: (value) => {
    return currency(value, {
      symbol: "$",
      precision: 2,
      fromCents: true,
      separator: ",",
      decimal: ".",
      pattern: "!#"
    }).format().replace(".00", "");
  },
  eur: (value) => {
    return currency(value, {
      symbol: "€",
      precision: 2,
      fromCents: true,
      separator: ".",
      decimal: ",",
      pattern: "#!"
    }).format().replace(",00", "");
  }
}

export default {
  methods: {
    updateTeamSize(event) {
      if(parseInt(event.target.value) < MIN_TEAM_SIZE) {
        return;
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isGuest"]),
    ctaCopy() {
      if(this.teamSize === 1) {
        return `Buy ${this.teamSize} license`;
      } else {
        return `Buy ${this.teamSize} licenses`;
      }
    },
    purchaseHref() {
      return `/purchases/new?slug=course_team&quantity=${this.teamSize}&coupon_code=${this.activeCouponCode}`;
    },
    originalPrice() {
      return currencyFormatter[this.product.currency](this.teamSize * this.product.originalPrice);
    },
    showOriginalPrice() {
      return this.teamSize >= this.minDiscountableTeamSize;
    },
    minDiscountableTeamSize() {
      const teamSizes = Object.keys(this.product.quantityCoupons).
        map(n => parseInt(n)).sort((a, b) => b - a);

      return teamSizes[teamSizes.length - 1];
    },
    finalPrice() {
      // quantityCoupons structure
      //
      // {
      //   "5":{"percent_off":5,"coupon":"TEAM5"},
      //   "10":{"percent_off":7,"coupon":"TEAM7"},
      //   "15":{"percent_off":10,"coupon":"TEAM10"},
      //   "50":{"percent_off":15,"coupon":"TEAM15"},
      //   "100":{"percent_off":30,"coupon":"TEAM30"}
      // }
      //
      const teamSizes = Object.keys(this.product.quantityCoupons).
        map(n => parseInt(n)).sort((a, b) => b - a);

      const currentTeamSize = this.teamSize;
      const offerTeamSize = teamSizes.find(n => currentTeamSize >= n);

      let percent_off = 0;
      let price = this.product.originalPrice;
      this.activeCouponCode = null;

      if(offerTeamSize) {
        percent_off = this.product.quantityCoupons[offerTeamSize].percent_off;
        price = (this.product.originalPrice * (100 - percent_off) / 100.0) * this.teamSize;
        this.activeCouponCode = this.product.quantityCoupons[offerTeamSize].coupon;
      }

      return currencyFormatter[this.product.currency](price);
    }
  },
  data() {
    return {
      teamSize: MIN_TEAM_SIZE + 1,
      minTeamSize: MIN_TEAM_SIZE,
      activeCouponCode: null
    }
  },
  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mounted() {
    // To highlight that more linceses == bigger discount
    this.teamSize = this.minDiscountableTeamSize;
  }
}
</script>

<style lang="scss">
.team-size-calculator {
  &__form {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include breakpoint($md) {
      flex-direction: row;
    }
  }

  &__size-input {
    width: 100%;

    @include breakpoint($md) {
      width: auto;
      margin-right: $px8;
    }
  }

  &__cta {
    display: inline-block;
    width: 100%;
    margin-top: $px16;

    @include breakpoint($md) {
      width: auto;
    }
  }

  &__prices {
    display: flex;
    align-items: center;
    margin-bottom: $px16;
  }

  &__original-price {
    margin-right: $px16;

    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    text-decoration: line-through;
    color: $grey-7;
  }

  &__final-price {
    font-size: 48px;
    line-height: 48px;
    font-weight: 600;
  }
}
</style>
