import axios from "axios";

const loadContentItemNavPartial = ($contentItemNav) => {
  const { contentType, slug } = $contentItemNav.dataset;

  const params = {
    content_type: contentType,
    slug
  };

  axios.get("/api/partials/content-item-navigation.json", {
    params: params
  }).then(response => {
    const params = response.data;

    if(params.partial) {
      $contentItemNav.outerHTML = params.partial;
    }
  });
}

export default loadContentItemNavPartial;
