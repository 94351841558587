import { nextSiblingWithClass } from "helpers/next-sibling-with-class";

document.addEventListener("click", e => {
  if(e.target && e.target.matches(".js-reveal-quiz-answer")) {
    e.preventDefault();

    const $showLink = e.target;
    const $inlineQuiz = nextSiblingWithClass($showLink.closest("p"), ".inline-quiz");

    if(!$inlineQuiz) {
      return;
    }

    $inlineQuiz.classList.add("inline-quiz--active");

    $showLink.remove();
  }
});
