const colorPallette = [
  "#8a3ffc",
  "#33b1ff",
  "#007d79",
  "#ff7eb6",
  "#fa4d56",
  "#6fdc8c",
  "#4589ff",
  "#d12771",
  "#d2a106",
  "#08bdba",
  "#bae6ff",
  "#ba4e00",
  "#d4bbff",
  "#7b8794"
];

export const MAX_X_AXIS_TICKS = 15;

const detectTimeAggregation = (fields, result, xAxis) => {
  const fieldsIndexes = {};
  fields.forEach((columnName, i) => {
    fieldsIndexes[columnName] = i;
  });

  const rawXValues = result.map(row => row[fieldsIndexes[xAxis]]);
  const xValues = [...new Set(rawXValues)].map(v => new Date(v)).sort((a, b) => {
    return a - b;
  });

  let relativeDiffs = [];
  xValues.forEach((v, i) => {
    if(i > 0) {
      relativeDiffs.push(v - xValues[i - 1]);
    }
  });

  const relativeDiffsDistr = relativeDiffs.reduce((acc, diff) => {
    if(acc[diff]) {
      acc[diff] += 1;
    } else {
      acc[diff] = 1;
    }

    return acc;
  }, {});

  const aggregation = Object.keys(relativeDiffsDistr).sort((a, b) => {
    return relativeDiffsDistr[b] - relativeDiffsDistr[a];
  })[0];
  // in case there's a tie, we need to return the lowest key

  return aggregation;
};

export const calculateXAxisTimeUnit = (fields, result, xAxis) => {
  const fieldsIndexes = {};
  fields.forEach((columnName, i) => {
    fieldsIndexes[columnName] = i;
  });

  const xValues = result.map(r => r[fieldsIndexes[xAxis]]);
  const orderedXValues = [...new Set(xValues)].map(v => new Date(v)).sort((a, b) => a - b);

  const chartInterval = orderedXValues[orderedXValues.length - 1] - orderedXValues[0];
  const aggregationUnit = detectTimeAggregation(fields, result, xAxis);
  const fitUnits = chartInterval / aggregationUnit;

  if(aggregationUnit >= 365 * 24 * 60 * 60 * 1000) {
    return "year";
  } else if(aggregationUnit >= 89 * 24 * 60 * 60 * 1000) {
    return "quarter";
  } else if(aggregationUnit >= 30 * 24 * 60 * 60 * 1000) {
    return "month";
  } else if(aggregationUnit == 24 * 60 * 60 * 1000) {
    if(fitUnits > 60) {
      return "month";
    } else {
      return "day"
    }
  } else {
    return "month";
  }

  // TODO finish this function to set a proper time unit for xAxis
};

export const calculateXAxisType = (fields, fieldTypes, xAxis) => {
  if (!xAxis) {
    return "category";
  }

  const fieldsIndexes = {};
  fields.forEach((columnName, i) => {
    fieldsIndexes[columnName] = i;
  });

  const xAxisColumnIndex = fieldsIndexes[xAxis];
  const xAxisColumnType = fieldTypes[xAxisColumnIndex];

  if(xAxisColumnType === "date" || xAxisColumnType === "timestamp") {
    return "time";
  } else if(xAxisColumnType === "integer" || xAxisColumnType === "float") {
    return "linear";
  } else {
    return "category";
  }
};

const generateSeriesKey = (yAxis, yAxisColumn, seriesValues) => {
  if(yAxis.length > 1) {
    if(seriesValues.length > 0) {
      return `${seriesValues.join(",")}-${yAxisColumn}`;
    } else {
      return yAxisColumn;
    }
  } else if(seriesValues.length > 0) {
    return seriesValues.join(",");
  } else {
    return "default";
  }
};

export const calculateLabels = (fields, result, xAxis, xAxisType) => {
  if(!xAxis) {
    return [];
  }

  const columnIndex = fields.findIndex(name => name === xAxis);

  const labels = [];
  const seenLabels = {};

  result.forEach(row => {
    const label = row[columnIndex];

    if(!seenLabels[label]) {
      labels.push(label);
      seenLabels[label] = true;
    }
  });

  let orderedLabels;
  let timeAggregation;
  if(xAxisType === "time") {
    orderedLabels = labels.sort((a, b) => {
      return new Date(a) - new Date(b);
    });

    timeAggregation = detectTimeAggregation(fields, result, xAxis);
  } else if (xAxisType === "linear") {
    orderedLabels = labels.sort((a, b) => {
      return parseFloat(a, 10) - parseFloat(b, 10);
    });
  } else {
    orderedLabels = labels;
  }

  // if(xAxisType === "time") {
  //   let uniformOrderedLabels = [];

  //   orderedLabels = orderedLabels.map(l => new Date(l).getTime());
  //   orderedLabels.forEach((l, i) => {
  //     if(i < orderedLabels.length) {
  //       uniformOrderedLabels.push(l);

  //       let j = 1;
  //       while(l + j * timeAggregation < orderedLabels[i + 1]) {
  //         uniformOrderedLabels.push(l + j * timeAggregation);
  //         j += 1;
  //       }
  //     }
  //   });

  //   orderedLabels = uniformOrderedLabels;
  // }

  return orderedLabels;
}

const calculatePieDatasets = (fields, result, seriesColumns, yAxis, labels, type) => {
  if(yAxis.length === 0) {
    return [];
  }

  const fieldsIndexes = {};

  fields.forEach((columnName, i) => {
    fieldsIndexes[columnName] = i;
  });

  const datasetSeriesValues = {};

  result.forEach(row => {
    const seriesValues = [];
    seriesColumns.forEach(columnName => {
      seriesValues.push(row[fieldsIndexes[columnName]]);
    });

    yAxis.forEach(yAxisColumn => {
      const seriesKey = generateSeriesKey(yAxis, yAxisColumn, seriesValues);
      const yValue = row[fieldsIndexes[yAxisColumn]];

      if(!datasetSeriesValues[seriesKey]) {
        datasetSeriesValues[seriesKey] = 0
      }

      datasetSeriesValues[seriesKey] += parseFloat(yValue, 10);
    });
  });

  const datasets = [];

  Object.keys(datasetSeriesValues).forEach((seriesKey, i) => {
    const data = datasetSeriesValues[seriesKey];

    datasets.push({
      label: seriesKey,
      fill: true,
      data: data,
      backgroundColor: colorPallette[i % colorPallette.length]
    });
  });

  return datasets;
};

export const calculateDatasets = (fields, result, seriesColumns, xAxis, yAxis, labels, type, xAxisType, fieldTypes) => {
  if(type === "pie") {
    return calculatePieDatasets(fields, result, seriesColumns, yAxis, labels, type);
  } else {

    if(!xAxis) {
      return [];
    }

    if(yAxis.length === 0) {
      return [];
    }

    const fieldsIndexes = {};

    fields.forEach((columnName, i) => {
      fieldsIndexes[columnName] = i;
    });

    const datasetSeriesValues = {};

    let orderedResult;
    const xAxisColumnIndex = fieldsIndexes[xAxis]
    if(xAxisType === "time") {
      orderedResult = result.sort((a, b) => {
        return new Date(a[xAxisColumnIndex]) > new Date(b[xAxisColumnIndex]);
      });
    } else if (xAxisType === "linear") {
      orderedResult = result.sort((a, b) => {
        return parseFloat(a[xAxisColumnIndex], 10) > parseFloat(b[xAxisColumnIndex], 10);
      });
    } else {
      orderedResult = result;
    }

    orderedResult.forEach(row => {
      const seriesValues = [];
      seriesColumns.forEach(columnName => {
        seriesValues.push(row[fieldsIndexes[columnName]]);
      });

      yAxis.forEach(yAxisColumn => {
        const seriesKey = generateSeriesKey(yAxis, yAxisColumn, seriesValues);
        const yValue = row[fieldsIndexes[yAxisColumn]];
        let xLabel = row[fieldsIndexes[xAxis]];

        // if(xAxisType === "time") {
        //   xLabel = new Date(xLabel).getTime();
        // }

        if(!datasetSeriesValues[seriesKey]) {
          datasetSeriesValues[seriesKey] = {}
        }

        if(!datasetSeriesValues[seriesKey][xLabel]) {
          datasetSeriesValues[seriesKey][xLabel] = 0;
        }

        const yValueType = fieldTypes[yAxisColumn];
        if(yValueType === "integer") {
          datasetSeriesValues[seriesKey][xLabel] += parseInt(yValue, 10);
        } else {
          datasetSeriesValues[seriesKey][xLabel] += parseFloat(yValue, 10);
        }
      });
    });

    const datasets = [];

    Object.keys(datasetSeriesValues).forEach((seriesKey, i) => {
      const data = [];

      labels.forEach(xLabel => {
        if(typeof(datasetSeriesValues[seriesKey][xLabel]) === "number") {
          data.push({
            x: xLabel,
            y: datasetSeriesValues[seriesKey][xLabel]
          });
        }
      });

      datasets.push({
        label: seriesKey,
        lineTension: 0.05,
        fill: type === "bar",
        data: data,
        backgroundColor: colorPallette[i % colorPallette.length],
        borderColor: colorPallette[i % colorPallette.length]
      });
    });

    return datasets;
  }
};

const countInArray = (array, values) => {
  return array.reduce((count, v) => {
    return values.includes(v) ? count + 1 : count
  }, 0);
};

export const detectAxesAndSeries = (fields, fieldTypes) => {
  const result = {
    yAxes: []
  };

  const fieldsIndexes = {};
  fields.forEach((columnName, i) => {
    fieldsIndexes[columnName] = i;
  });

  const timeColumnsCount = countInArray(fieldTypes, ["date", "timestamp"]);
  if(timeColumnsCount === 1) {
    const timeColumnIndex = fieldTypes.findIndex(type => ["date", "timestamp"].includes(type));
    result.xAxis = fields[timeColumnIndex];
  }

  const numericColumnsCount = countInArray(fieldTypes, ["integer", "float"]);
  if(numericColumnsCount === 1) {
    const numericColumnIndex = fieldTypes.findIndex(type => ["integer", "float"].includes(type));
    result.yAxes.push(fields[numericColumnIndex]);
  } else if(numericColumnsCount > 1 && timeColumnsCount === 1) {
    fieldTypes.forEach((type, fieldIndex) => {
      if(["integer", "float"].includes(type)) {
        result.yAxes.push(fields[fieldIndex]);
      }
    });
  }

  const textColumnsCount = countInArray(fieldTypes, ["text"]);
  if(textColumnsCount === 1 && numericColumnsCount === 1 && timeColumnsCount === 0) {
    const textColumnIndex = fieldTypes.findIndex(type => ["text"].includes(type));
    result.xAxis = fields[textColumnIndex];
    result.chartType = "bar";
  } else if(textColumnsCount === 1 && timeColumnsCount > 0) {
    const textColumnIndex = fieldTypes.findIndex(type => ["text"].includes(type));
    result.series = fields[textColumnIndex];
  }

  if(timeColumnsCount === 0 && textColumnsCount === 1 && !result.chartType) {
    result.chartType = "pie";
  } else if(timeColumnsCount === 1 && textColumnsCount > 0) {
    result.chartType = "bar";
  } else if(timeColumnsCount === 1) {
    result.chartType = "line";
  } else {
    result.chartType = "bar";
  }

  return result;
};
