import { getCookie, deleteCookie, setCookie } from "modules/cookie";

const NAVBAR_COOKIE = "sqlh_mdn_navbar_closed";
const $mdnNavbar = document.querySelector(".js-mdn-navbar");

if ($mdnNavbar) {
  const $closeIcon = $mdnNavbar.querySelector(".mdn-navbar__close");
  $closeIcon.classList.add("active-block");

  const $openIcon = $mdnNavbar.querySelector(".mdn-navbar__open-container");

  const $activeTab = $mdnNavbar.querySelector(".mdn-navbar__active-tab");
  const $inactiveTab = $mdnNavbar.querySelector(".mdn-navbar__inactive-tab");

  const $mdnBody = document.querySelector(".mdn-page__body");

  const closeNavbar = () => {
    $activeTab.classList.add("hidden");
    $inactiveTab.classList.add("active-block");
    $mdnBody.classList.add("mdn-page__body--navbar-closed");

    setCookie(NAVBAR_COOKIE, true, 365);
  }

  if (getCookie(NAVBAR_COOKIE)) {
    closeNavbar();
  }

  $closeIcon.addEventListener("click", e => {
    closeNavbar();

    window.state.initMDNPageToc();
  })

  $openIcon.addEventListener("click", e => {
    deleteCookie(NAVBAR_COOKIE);

    $activeTab.classList.remove("hidden");
    $inactiveTab.classList.remove("active-block");
    $mdnBody.classList.remove("mdn-page__body--navbar-closed");

    window.state.initMDNPageToc();
  })
}
