import Axios from "axios";
import Sentry from "modules/sentry";

(function() {
  const $contentsPage = document.querySelector(".contents-page");

  if(!$contentsPage) {
    return;
  }

  let chaptersWithContentItems = {};

  const loadChapterContentItems = $chapterContents => {
    const $spinner = $chapterContents.querySelector(".chapter-contents__content-items-spinner");

    if($spinner) {
      $spinner.classList.add("chapter-contents__content-items-spinner--active");
    }

    const chapterId = $chapterContents.dataset.chapterId;

    if(chaptersWithContentItems[chapterId]) {
      return;
    }

    Axios
      .get(`/api/chapters/${chapterId}/content_items`)
      .then(response => {
        chaptersWithContentItems[chapterId] = true;

        $chapterContents.querySelector(".chapter-contents__content-items").innerHTML = response.data.content_items_html;

        $spinner.classList.remove("chapter-contents__content-items-spinner--active");
      })
      .catch(error => {
        Sentry.captureException(error)
      });
  };

  $contentsPage.addEventListener("click", function(e) {
    const $chapterContentsCard = e.target && e.target.closest(".chapter-contents__card");

    if($chapterContentsCard) {
      const $chapterContents = $chapterContentsCard.closest(".chapter-contents");
      const chapterId = $chapterContents.dataset.chapterId;

      $chapterContents.classList.toggle("chapter-contents--active");

      loadChapterContentItems($chapterContents);
    }
  });

  Axios
    .get("/api/users/chapters_progress")
    .then(response => {
      const finishedContentItemsPerChapter = response.data.content_items_per_chapter;
      let activeChapterActivated = false;

      document.querySelectorAll(".chapter-contents").forEach($chapterContents => {
        const $chapterProgress = $chapterContents.querySelector(".js-chapter-progress");
        const chapterId = $chapterContents.dataset.chapterId;
        const contentItemsCount = $chapterContents.dataset.contentItemsCount;
        const chapterProgress = finishedContentItemsPerChapter[chapterId] || 0;

        $chapterProgress.innerHTML = chapterProgress + "&nbsp;";

        if(chapterProgress == contentItemsCount) {
          $chapterContents.querySelector(".chapter-contents__status").classList.add("chapter-contents__status--active");
        } else if(!activeChapterActivated) {
          loadChapterContentItems($chapterContents);

          $chapterContents.classList.add("chapter-contents--active");

          activeChapterActivated = true;
        }
      });
    })
    .catch(error => {
      Sentry.captureException(error)
    });
})();
