import { getCookie, setCookie } from "modules/cookie";
import { recordCookieConsent } from "modules/track-cookie-consent";

(function() {
  const $toggle = document.querySelector(".cookie-consent-toggle");

  if(!$toggle) {
    return;
  }

  const COOKIE_NAME = "cookie-consent";
  const $status = $toggle.querySelector(".cookie-consent-toggle__status");
  const $checkbox = $toggle.querySelector(".cookie-consent-toggle__checkbox");

  const setStatus = (status) => {
    if(status) {
      $status.textContent = "Third Party cookies are enabled";
    } else {
      $status.textContent = "Third Party cookies are disabled";
    }
  };

  if(getCookie(COOKIE_NAME) === "true") {
    $checkbox.checked = true;
  } else {
    $checkbox.checked = false;
  }

  setStatus($checkbox.checked);

  $checkbox.addEventListener("change", function() {
    setCookie(COOKIE_NAME, this.checked, 365);

    setStatus(this.checked);

    recordCookieConsent();
  });
})();
