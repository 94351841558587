const mutations = {
  authentication(state, params) {
    state.isGuest = params.guest;
    state.authToken = params.authToken;
    state.username = params.username;
    state.segment = params.segment;
  },
  setUsername(state, input) {
    state.username = input;
  }
};

export default mutations;
