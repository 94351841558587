import Vue from "vue/dist/vue.esm";

import {
  calculateXAxisType,
  calculateLabels,
  calculateDatasets,
  detectAxesAndSeries,
  calculateXAxisTimeUnit
} from "./helpers";

const mutations = {
  currentDatabaseId(state, databaseId) {
    state.currentDatabaseId = databaseId;
  },
  databases(state, payload) {
    state.databases = payload.databases;
    state.currentDatabaseId = payload.currentDatabaseId || payload.databases[0].id;
  },
  sqlReference(state, reference) {
    state.sqlReference = reference;
  },
  data: (state, payload) => {
    // Delete columns that are no longer part of the result set
    state.seriesColumns = state.seriesColumns.filter(c => payload.fields.includes(c));
    state.yAxis = state.yAxis.filter(c => payload.fields.includes(c));

    if(!payload.fields) {
      state.fields = [];
      state.result = [];
      return;
    }

    if(payload.fields.every(c => !state.fields.includes(c))) {
      state.fields = [];
      state.result = [];
    }

    if(Object.keys(state.fields).length === 0 && Object.keys(state.result).length === 0) {
      const axesAndSeries = detectAxesAndSeries(payload.fields, payload.fieldTypes);

      if(axesAndSeries.xAxis) {
        state.xAxis = axesAndSeries.xAxis;
      }

      if(axesAndSeries.yAxes.length > 0) {
        axesAndSeries.yAxes.forEach(yAxis => {
          state.yAxis.push(yAxis);
        })
      }

      if(axesAndSeries.series) {
        state.seriesColumns.push(axesAndSeries.series);
      }

      if(axesAndSeries.chartType) {
        state.type = axesAndSeries.chartType;
      }
    }

    state.fields = payload.fields;
    state.result = payload.result;
    state.fieldTypes = payload.fieldTypes;
    state.tableColumns = [...payload.fields];

    state.xAxisType = calculateXAxisType(state.fields, state.fieldTypes, state.xAxis);
    state.labels = calculateLabels(state.fields, state.result, state.xAxis, state.xAxisType);
    state.datasets = calculateDatasets(state.fields, state.result, state.seriesColumns, state.xAxis, state.yAxis, state.labels, state.type, state.xAxisType, state.fieldTypes);
    state.xAxisTimeUnit = calculateXAxisTimeUnit(state.fields, state.result, state.xAxis);
    state.datasetUpdatedAt = new Date();
  },
  chartType: (state, type) => {
    state.type = type;

    if(state.result && state.fields) {
      state.xAxisType = calculateXAxisType(state.fields, state.fieldTypes, state.xAxis);
      state.labels = calculateLabels(state.fields, state.result, state.xAxis, state.xAxisType);
      state.datasets = calculateDatasets(state.fields, state.result, state.seriesColumns, state.xAxis, state.yAxis, state.labels, state.type, state.xAxisType, state.fieldTypes);
      state.xAxisTimeUnit = calculateXAxisTimeUnit(state.fields, state.result, state.xAxis);
      state.datasetUpdatedAt = new Date();
    }
  },
  title(state, title) {
    state.title = title;

    state.datasetUpdatedAt = new Date();
  },
  xAxisTitle: (state, title) => {
    state.xAxisTitle = title;

    state.datasetUpdatedAt = new Date();
  },
  yAxisTitle: (state, title) => {
    state.yAxisTitle = title;

    state.datasetUpdatedAt = new Date();
  },
  xAxis: (state, payload) => {
    state.xAxis = payload.value;

    state.xAxisType = calculateXAxisType(state.fields, state.fieldTypes, state.xAxis);
    state.labels = calculateLabels(state.fields, state.result, state.xAxis, state.xAxisType);
    state.datasets = calculateDatasets(state.fields, state.result, state.seriesColumns, state.xAxis, state.yAxis, state.labels, state.type, state.xAxisType, state.fieldTypes);
    state.xAxisTimeUnit = calculateXAxisTimeUnit(state.fields, state.result, state.xAxis);
    state.datasetUpdatedAt = new Date();
  },
  yAxis: (state, payload) => {
    if(payload.checked) {
      if(!state.yAxis.includes(payload.value)) {
        state.yAxis.push(payload.value);
      }
    } else {
      const i = state.yAxis.indexOf(payload.value);

      state.yAxis.splice(i, 1);
    }

    const fieldsIndexes = {};
    state.fields.forEach((columnName, i) => {
      fieldsIndexes[columnName] = i;
    });
    const yAxisIsValid = state.yAxis.every(yAxisColumnName => {
      const yAxisColumnIndex = fieldsIndexes[yAxisColumnName];
      const yAxisColumnType = state.fieldTypes[yAxisColumnIndex];

      return yAxisColumnType === "integer" || yAxisColumnType === "float";
    });

    if(yAxisIsValid) {
      Vue.delete(state.errors, "yAxis");
    } else {
      Vue.set(state.errors, "yAxis", `:warning: Y-axis needs to be a number.`);
    }

    state.xAxisType = calculateXAxisType(state.fields, state.fieldTypes, state.xAxis);
    state.labels = calculateLabels(state.fields, state.result, state.xAxis, state.xAxisType);
    state.datasets = calculateDatasets(state.fields, state.result, state.seriesColumns, state.xAxis, state.yAxis, state.labels, state.type, state.xAxisType, state.fieldTypes);
    state.xAxisTimeUnit = calculateXAxisTimeUnit(state.fields, state.result, state.xAxis);
    state.datasetUpdatedAt = new Date();
  },
  tableColumns: (state, payload) => {
    if(payload.checked) {
      if(!state.tableColumns.includes(payload.value)) {
        state.tableColumns.push(payload.value);
      }
    } else {
      const i = state.tableColumns.indexOf(payload.value);

      state.tableColumns.splice(i, 1);
    }

    state.tableColumns = state.fields.filter(field => state.tableColumns.includes(field))
  },
  seriesColumns: (state, payload) => {
    if(payload.checked) {
      if(!state.seriesColumns.includes(payload.value)) {
        state.seriesColumns.push(payload.value);
      }
    } else {
      const i = state.seriesColumns.indexOf(payload.value);

      state.seriesColumns.splice(i, 1);
    }

    state.xAxisType = calculateXAxisType(state.fields, state.fieldTypes, state.xAxis);
    state.labels = calculateLabels(state.fields, state.result, state.xAxis, state.xAxisType);
    state.datasets = calculateDatasets(state.fields, state.result, state.seriesColumns, state.xAxis, state.yAxis, state.labels, state.type, state.xAxisType, state.fieldTypes);
    state.xAxisTimeUnit = calculateXAxisTimeUnit(state.fields, state.result, state.xAxis);
    state.datasetUpdatedAt = new Date();
  },
  stacked: (state, isStacked) => {
    state.stacked = isStacked;

    state.datasetUpdatedAt = new Date();
  },
  showAllXAxisLabels: (state, checked) => {
    state.showAllXAxisLabels = checked;

    state.datasetUpdatedAt = new Date();
  },
  legendPosition(state, position) {
    state.legendPosition = position;

    state.datasetUpdatedAt = new Date();
  },
  clearState(state) {
    state.xAxis = null;
    state.yAxis = [];
    state.seriesColumns = [];
    state.series = [];
    state.title = null;
    state.xAxisTitle = null;
    state.xAxisType = "category";
    state.yAxisTitle = null;
    state.stacked = true;
    state.fields = [];
    state.fieldTypes = [];
    state.result = [];
    state.labels = [];
    state.datasetUpdatedAt = null;
    state.errors = {};
    state.datasets = [];
    state.showAllXAxisLabels = false;
  }
};

export default mutations;
