<template>
  <DropdownMenu name="Dataset">
    <DropdownMenuItem
      v-for="dataset in customStore.state.datasets"
      :name="dataset"
      :key="dataset"
      :value="`dataset_name:${dataset}`" />
  </DropdownMenu>
</template>

<script>
import customStore from "../custom-store";
import DropdownMenu from "./dropdown-menu";
import DropdownMenuItem from "./dropdown-menu-item";

export default {
  name: "PracticeDatasetMenuItem",
  components: {
    DropdownMenu,
    DropdownMenuItem
  },
  data() {
    return {
      customStore: customStore
    }
  }
};
</script>
