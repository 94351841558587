const actions = {
  authenticate: ({commit}, params) => {
    commit("authentication", params);
  },
  setUsername: ({commit}, input) => {
    commit("setUsername", input);
  }
};

export default actions;
