const state = {
  type: "bar",
  types: ["line", "bar", "pie", "table"],
  legendPositions: ["top", "left", "bottom", "right"],
  legendPosition: "bottom",
  xAxis: null,
  yAxis: [],
  seriesColumns: [],
  tableColumns: [],
  series: [],
  title: null,
  xAxisTitle: null,
  xAxisType: "category",
  yAxisTitle: null,
  stacked: true,
  fields: [],
  fieldTypes: [],
  result: [],
  labels: [],
  datasetUpdatedAt: null,
  errors: {},
  datasets: [],
  databases: [],
  currentDatabaseId: null,
  showAllXAxisLabels: false,
  xAxisTimeUnit: "month", // TODO implement logic to set this value and use it in getters,
  sqlReference: null
};

export default state;
