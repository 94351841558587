import { getCookie, setCookie } from "modules/cookie";

const ABTEST_COOKIE_NAME = "sqh_ab";

const popupRandom = items => {
  return items[Math.floor(Math.random() * items.length)]
};

export const setupAbtests = () => {
  const $abTestBlocks = document.querySelectorAll(".js-abtest");

  let abTests = {};

  $abTestBlocks.forEach($abTestBlock => {
    const abTestName = $abTestBlock.dataset.abName;

    if(abTests[abTestName]) {
      abTests[abTestName].push($abTestBlock);
    } else {
      abTests[abTestName] = [$abTestBlock];
    }
  });

  let abTestsNames = Object.keys(abTests);

  if(abTestsNames.length === 0) {
    return;
  }

  let currentAbtestName;
  let currentVariationName;

  if(getCookie(ABTEST_COOKIE_NAME)) {
    [currentAbtestName, currentVariationName] = getCookie(ABTEST_COOKIE_NAME).split(":");
  } else {
    currentAbtestName = abTestsNames[0];
    currentVariationName = popupRandom(abTests[currentAbtestName]).dataset.abVariation;

    setCookie(ABTEST_COOKIE_NAME, `${currentAbtestName}:${currentVariationName}`, 365);
  }

  if(!currentAbtestName) {
    return;
  }

  abTestsNames.forEach(abTestName => {
    abTests[abTestName].forEach($abTest => {
      if ($abTest.dataset.abName === currentAbtestName) {
        if($abTest.dataset.abVariation === currentVariationName) {
          $abTest.style.display = "block";
        } else {
          $abTest.remove();
        }
      } else if($abTest.dataset.abVariation === "control") {
        $abTest.style.display = "block";
      } else {
        $abTest.remove();
      }
    });
  });

  return {
    abName: currentAbtestName,
    abVariation: currentVariationName
  }
};
