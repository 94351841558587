import { getCookie } from "modules/cookie";
import { checkIn } from "modules/pageview-analytics";
import { setupAbtests } from "modules/abtest-categorization";
import Vue from "vue/dist/vue.esm";
import VueI18n from "vue-i18n";
import twemoji from "twemoji";
import CookieConsent from "components/cookie-consent";
import { loadGTM } from "modules/gtm";

Vue.use(VueI18n);

let md = require("markdown-it")({
  html: true,
  linkify: true,
  typographer: true
});
let emoji = require("markdown-it-emoji");

md.use(emoji);

md.renderer.rules.emoji = function(token, idx) {
  return twemoji.parse(token[idx].content, {
    base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/'
  });
};

Vue.filter("md", value => {
  return md.renderInline(value);
});

const COOKIE_NAME = "cookie-consent";
const currentAbTest = setupAbtests();

checkIn(currentAbTest);

if(getCookie(COOKIE_NAME)) {
  if(getCookie(COOKIE_NAME) === "true") {
    loadGTM();
  }
} else {
  const $container = document.querySelector(".js-cookie-consent");

  new Vue({
    i18n: new VueI18n({
      locale: "en"
    }),
    el: $container,
    render: h => h(CookieConsent)
  });
}
