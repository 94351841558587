import Vue from "vue/dist/vue.esm"
import VueI18n from "vue-i18n";
import TeamSizeCalculator from "components/team-size-calculator.vue"
import axios from "axios";
import { setCouponCookie } from "controllers/promo-coupon-code";
import { store } from "store";
import Sentry from "modules/sentry";

setCouponCookie();

const $container = document.querySelector(".js-team-size-calculator");

if($container) {
  const couponCode = $container.dataset.couponCode;

  axios.get("/api/prices.json", {
    params: {
      slugs: ["course_team"],
      coupon_code: couponCode
    }
  }).then(response => {
    const products = response.data.products;
    const teamProduct = products.find(p => p.slug === "course_team");

    new Vue({
      el: $container,
      store,
      i18n: new VueI18n({
        locale: "en",
        silentTranslationWarn: process.env.NODE_ENV === "production"
      }),
      render: h => h(TeamSizeCalculator, {
        props: {
          product: teamProduct
        }
      })
    });
  }).catch(function(error) {
    Sentry.captureException(error);
  });
}
