import Vue from "vue/dist/vue.esm";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import FeaturesNav from "components/features-nav";

const $featuresNavContainers = document.querySelectorAll(".js-features-nav");

$featuresNavContainers.forEach(container => {
  const featuresScreenshotBlock = container.closest(".features-screenshots");
  const reversed = container.dataset.reversed === "true";
  let images = [];
  container.closest(".features-screenshots").querySelectorAll(".features-screenshots__feature").forEach(el => {
    images.push(el.dataset.image);
  });

  new Vue({
    el: container,
    i18n: new VueI18n({
      locale: "en"
    }),
    render: h => h(FeaturesNav, {
      props: {
        featuresScreenshotBlock,
        reversed,
        images
      }
    })
  });
});
