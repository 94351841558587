<template>
  <div class="blog-info-panel">
    <InfoIcon
      class="blog-info-panel__icon" />

    <div
      class="blog-info-panel__copy"
      v-html="copy" />
  </div>
</template>

<script>
import InfoIcon from "@images/font_awesome/info-circle-light.svg";

export default {
  name: "BlogInfoPanel",
  components: {
    InfoIcon
  },
  props: {
    copy: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.blog-info-panel {
  display: flex;
  padding: $px4 0;
  position: relative;

  border-radius: $px8;

  background-color: $blue-10;

  &__icon {
    position: absolute;
    top: 25px;
    left: $px24;
    width: $px24;
    height: $px24;

    color: $grey-4;
  }

  &__copy {
    padding: 0 $px24 0 70px;
  }
}
</style>
