import loadDesktopNavbarPartial from "modules/partials/load-desktop-navbar-partial";
import loadMobileNavbarPartial from "modules/partials/load-mobile-navbar-partial";
import loadFullScreenPromoPartial from "modules/partials/load-full-screen-promo-partial";
import loadForumSidebarMenuPartial from "modules/partials/load-forum-sidebar-menu-partial";
import loadLessonContentPartial from "modules/partials/load-lesson-content-partial";
import loadExerciseContentPartial from "modules/partials/load-exercise-content-partial";
import loadContentItemNavPartial from "modules/partials/load-content-item-nav-partial";
import loadPracticeExerciseCta from "modules/partials/load-practice-exercise-cta";

export default ({ userSegment }) => {
  const $body = document.querySelector("body");

  if(!$body.classList.contains("js-cdn-cached")) {
    return;
  }

  const $lessonContent = document.querySelector(".js-partial-lesson-content");
  if($lessonContent) {
    loadLessonContentPartial($lessonContent, userSegment);
  }

  const $exerciseContent = document.querySelector(".js-partial-exercise-content");
  if($exerciseContent) {
    loadExerciseContentPartial($exerciseContent, userSegment);
  }

  const $contentItemNav = document.querySelector(".js-partial-content-item-navigation");
  if($contentItemNav) {
    loadContentItemNavPartial($contentItemNav);
  }

  const $desktopNavbar = document.querySelector(".js-partial-desktop-navbar");
  if($desktopNavbar) {
    loadDesktopNavbarPartial($desktopNavbar);
  }

  const $mobileNavbar = document.querySelector(".js-partial-mobile-navbar");
  if($mobileNavbar) {
    loadMobileNavbarPartial($mobileNavbar);
  }

  const $fullScreenPromo = document.querySelector(".js-partial-full-screen-promo");
  if($fullScreenPromo) {
    loadFullScreenPromoPartial($fullScreenPromo);
  }

  const $forumSidebarMenu = document.querySelector(".js-partial-forum-sidebar-menu");
  if($forumSidebarMenu) {
    loadForumSidebarMenuPartial($forumSidebarMenu);
  }

  const $practiceExerciseCta = document.querySelector(".js-practice-exercise-cta");
  if($practiceExerciseCta) {
    loadPracticeExerciseCta($practiceExerciseCta);
  }
};
