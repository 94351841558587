import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://61e9c335e4e74abfbf4ea7e560972ced@sentry.io/1519978",
  release: window.sqlhabitRelease,
  environment: window.sqlhabitEnv,
  beforeSend: function(event) {
    if (["localhost", "127.0.0.1"].includes(window.location.hostname)) {
      event.exception.values.forEach(error => {
        console.log(error);
      });

      return null;
    }
    return event;
  },
  tracesSampleRate: 0
});

export default Sentry;
