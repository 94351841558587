const getters = {
  exerciseCompleted(state) {
    return state.completed;
  },
  exerciseSlug(state) {
    return state.slug;
  },
};

export default getters;
