import axios from "axios";
import mountQuestionSubscription from "controllers/forum/question-subscription";

const loadForumSidebarMenuPartial = ($forumSidebarMenu) => {
  const params = {};

  axios.get("/api/partials/forum-sidebar-menu.json", {
    params: params
  }).then(response => {
    const params = response.data;

    if(params.partial) {
      $forumSidebarMenu.outerHTML = params.partial;

      mountQuestionSubscription();
    }
  });
}

export default loadForumSidebarMenuPartial;
