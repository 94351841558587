<template>
  <div class="continue-banner" v-if="continueUrl">
    <a class="continue-banner__link-wrapper" :href="continueUrl">
      <div class="continue-banner__content">
        <h3 class="continue-banner__headline">
          Next {{ nextContentItem.type }}: <i>{{ nextContentItem.name }}</i>
        </h3>

        <a class="continue-banner__cta btn btn--sm btn--full-width-xs" v-if="userContentItems > 0">
          CONTINUE THE COURSE
        </a>

        <a class="continue-banner__cta btn btn--sm btn--full-width-xs" v-else>
          START THE COURSE
        </a>
      </div>
    </a>

    <div class="continue-banner__bg">
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Sentry from "modules/sentry";

export default {
  props: {
    userContentItems: {
      type: Number, default: 0
    }
  },
  computed: {
    continueUrl() {
      if(this.nextContentItem.url) {
        try {
          let url = new URL(this.nextContentItem.url);
          url.searchParams.append("from", "continue-banner");

          return url.toString();
        } catch(error) {
          Sentry.captureException(error);

          return;
        }
      } else {
        return;
      }
    }
  },
  data() {
    return {
      nextContentItem: {
        url: null,
        name: null,
        content_type: null
      }
    };
  },
  mounted() {
    axios
      .get("/api/users/next_content_item")
      .then(response => {
        this.nextContentItem = response.data
      })
      .catch(error => Sentry.captureException(error))
  }
}
</script>

<style lang="scss">
.continue-banner {
  $root: &;

  position: relative;
  padding: 0;
  overflow: hidden;

  border-radius: $px4;

  background-color: $grey-10;

  @include breakpoint($sm) {
    padding: 0 $px24;
  }

  &__bg {
    position: absolute;
    width: 300px;
    height: 300px;
    top: -200px;
    left: -100px;
    z-index: 1;

    border-radius: 150px;

    background-color: $cyan-9;

    opacity: 0.8;
  }

  &__link-wrapper {
    display: flex;
    align-items: center;
    padding: $px24;

    text-decoration: none;

    &:hover {
      opacity: 1;

      #{$root}__play-icon {
        width: $px72;
        height: $px72;

        color: $blue-5;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  &__headline {
    margin-top: 0;
    margin-bottom: $px16;
  }

  &__play-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: $px64;
    height: $px64;

    color: $blue-6;

    transition: width 0.5s, height 0.5s;

    @include breakpoint($md) {
      display: block;
    }
  }
}
</style>
