const COLORS = [
  "#BAE3FF",
  "#C1FEF6",
  "#FFD0B5",
  "#FFBDBD",
  "#FFF3C4",
  "#C6F7E2",
];

const randomItem = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

const randomFromRange = (min, max) => {
  return Math.random() * (max - min) + min;
};

document.querySelectorAll(".quiz-item-explanation__recommendation-wrapper").forEach($el => {
  const $circle = $el.querySelector(".quiz-item-explanation__recommendation-wrapper-circle");

  $circle.classList.add("quiz-item-explanation__recommendation-wrapper-circle--active");

  $circle.style.backgroundColor = randomItem(COLORS);

  const size = randomFromRange(150, 300);
  $circle.style.width = `${size}px`;
  $circle.style.height = `${size}px`;

  const xPosition = randomItem(["left", "right"]);
  const yPosition = randomItem(["top", "bottom"]);

  $circle.style[xPosition] = `${-size / 2}px`;
  $circle.style[yPosition] = `${-size / 2}px`;
});
