<template>
  <div class="five-star">
    <div class="five-star__question">
      How did you like this {{ contentItemName }}?
    </div>

    <div class="five-star__stars">
      <a
        href="#"
        class="five-star__rating-button"
        v-for="index in [1, 2, 3, 4, 5]"
        :key="index"
        @click.prevent="rate(index)"
        @mouseenter.prevent="hoverIndex = index"
        @mouseleave.prevent="hoverIndex = null">
        <div class="five-star__rating-button-body">
          <LightStarIconFilled
            v-if="hoverIndex && index <= hoverIndex"
            class="five-star__rating-icon" />

          <LightStarIcon
            v-else
            class="five-star__rating-icon" />
        </div>
      </a>
    </div>

    <div
      class="five-star__rating-button-label"
      :class="`five-star__rating-button-label--${hoverIndex}`">
      <span v-if="hoverIndex">
        {{ $tc(`ratingLabel.${hoverIndex}`) }}
      </span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { notify } from "modules/notify";
import LightStarIcon from "@images/font_awesome/star-lightest.svg";
import LightStarIconFilled from "@images/font_awesome/star-lightest-filled.svg";
import Sentry from "modules/sentry";

export default {
  name: "FiveStarRating",
  data() {
    return {
      hoverIndex: null
    }
  },
  computed: {
    contentItemName() {
      if(this.contentItem.contentType === "MockInterviews::Exercise") {
        return "exercise";
      } else {
        return this.contentItem.contentType;
      }
    }
  },
  methods: {
    rate: function(value) {
      let formData = new FormData();

      formData.append(
        "content_item_rating[rating_type]",
        "5star"
      );

      formData.append(
        "content_item_rating[value]",
        value
      );

      formData.append(
        "content_item_rating[content_id]",
        this.contentItem.contentId
      );

      formData.append(
        "content_item_rating[content_type]",
        this.contentItem.contentType
      );

      axios.post(this.rating.createUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        notify({
          message: "Thank you for the rating!"
        });

        const contentItemRating = response.data.rating;

        this.$emit("numericRatingSaved", contentItemRating.id, value);
      }).catch(function (error) {
        notify({
          message: "Smth went wrong",
          type: "alert"
        });

        Sentry.captureException(error);
      });
    }
  },
  props: {
    contentItem: Object,
    rating: Object
  },
  components: {
    LightStarIcon,
    LightStarIconFilled
  },
  i18n: {
    messages: {
      en: {
        ratingLabel: {
          1: "Terrible exercise",
          2: "Bad exercise",
          3: "It was OK",
          4: "Good exercise",
          5: "Great exercise"
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .five-star {
    display: flex;
    flex-direction: column;

    &__question {
      margin-bottom: $px8;
    }

    &__stars {
      display: flex;
      align-items: center;
    }

    &__rating-button {
      &:not(:last-child) {
        padding-right: $px16;
      }

      &:hover {
        opacity: 1;
      }
    }

    &__rating-button-body {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
    }

    &__rating-button-label {
      display: flex;
      margin-top: $px8;
      min-height: 18px;

      @include font-small;
      word-break: nowrap;

      &--1 {
        margin-left: 30px;
      }

      &--2 {
        margin-left: 30px;
      }

      &--3 {
        justify-content: center;
      }

      &--4 {
        justify-content: end;
        margin-right: 30px;
      }

      &--5 {
        justify-content: end;
      }
    }

    &__rating-icon {
      width: 38px;
      height: 38px;
    }
  }
</style>
