<template>
  <div class="promo-banner" v-if="copy">
    <div class="promo-banner__container">
      <div class="promo-banner__body">
        <img
          :src="partner_logo_url"
          class="promo-banner__logo"
          alt="SQL Habit Course">
        <div
          class="promo-banner__copy"
          v-html="copy" />
      </div>

      <a
        :href="cta_url"
        class="promo-banner__cta btn btn--full-width-xs js-open-signup-popup"
        :data-after-auth="cta_url">
        {{ cta }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PromoBanner",
  props: ["copy", "cta", "partner_logo_url", "cta_url"]
}
</script>

<style lang="scss">
.promo-banner {
  $root: &;

  position: relative;
  padding: $px32 0;
  z-index: 5000;

  box-shadow: 0px 0px $px2 $px2 $grey-9;

  background-color: $cyan-10;

  &__container {
    @include container;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    max-width: 600px;
    margin-bottom: $px8;

    font-weight: 800;
  }

  &__logo {
    max-width: $px192;
    margin-right: $px16;
    margin-bottom: $px8;
    flex-shrink: 0;
  }

  @include breakpoint($md) {
    #{$root}__container {
      flex-direction: row;
    }

    #{$root}__body {
      margin-bottom: 0;
      flex-direction: row;
      align-items: center;
    }

    #{$root}__logo {
      margin-bottom: 0;
    }
  }
}
</style>
