import Vue from "vue/dist/vue.esm";
import algoliasearch from "algoliasearch/lite";
import AlgoliaSearchPopup from "components/search/popup";

const showSearch = (algoliaIndex, algoliaAppName, algoliaApiKey) => {
  const client = algoliasearch(algoliaAppName, algoliaApiKey);
  const index = client.initIndex(algoliaIndex);

  const $body = document.querySelector("body");

  const $searchContainer = document.createElement("div");
  $searchContainer.classList = "js-algolia-search-container";

  $body.appendChild($searchContainer);

  new Vue({
    el: $searchContainer,
    render: h => h(AlgoliaSearchPopup, {
      props: {
        searchIndex: index
      }
    })
  });
};

document.addEventListener("click", function(e) {
  if(e.target && e.target.matches(".js-navbar-search-icon")) {
    e.preventDefault();

    const $searchIcon = e.target;

    const { algoliaIndex, algoliaAppName, algoliaApiKey } = $searchIcon.dataset;

    showSearch(algoliaIndex, algoliaAppName, algoliaApiKey);
  }
});

document.addEventListener("keydown", function(e) {
  if (!((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode == 75)) { // CMD + k
    return;
  }

  if(document.querySelector(".search-popup")) {
    return;
  }

  const $searchIcon = document.querySelector(".js-navbar-search-icon");
  const { algoliaIndex, algoliaAppName, algoliaApiKey } = $searchIcon.dataset;

  showSearch(algoliaIndex, algoliaAppName, algoliaApiKey);
});
