<template>
  <div class="product-price" :class="bemModifiers">
    <div class="product-price__original-price" v-if="product.hasDiscount">
      {{ product.originalPriceFormatted }}
    </div>

    <div class="product-price__final-price">
      {{ product.finalPriceFormatted }}
    </div>

    <div
      class="product-price__per-license font-small ml-8"
      v-if="showPerLicenseCopy">
      per license
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    bemModifiers() {
      return this.modifiers.map(m => {
        return `product-price--${m}`
      }).join(" ");
    },
    showPerLicenseCopy() {
      return this.product.slug === "course_team";
    }
  },
  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      }
    },
    modifiers: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
}
</script>

<style lang="scss">
.product-price {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: center;

  &__original-price {
    margin-right: $px16;

    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    text-decoration: line-through;
    color: $grey-7;
  }

  &__final-price {
    font-size: 48px;
    line-height: 48px;
    font-weight: 600;
  }

  &--align-left {
    justify-content: flex-start;
  }

  &--m {
    #{$root}__original-price {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      text-decoration: line-through;
      color: $grey-7;
    }

    #{$root}__final-price {
      font-size: 32px;
      line-height: 32px;
      font-weight: 600;
    }
  }
}
</style>
