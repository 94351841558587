export default () => {
  const $exercise = document.querySelector(".exercise");

  if(!$exercise) {
    return;
  }

  const $showSolutionLink = $exercise.querySelector(".exercise__show-solution");
  const $solution = $exercise.querySelector(".exercise__solution");

  if($showSolutionLink) {
    $showSolutionLink.addEventListener("click", (event) => {
      event.preventDefault();

      $solution.classList.add("exercise__solution--active");
      $showSolutionLink.parentNode.removeChild($showSolutionLink);
    });
  }

  const $hint = $exercise.querySelector(".exercise__hint");

  if($hint) {
    const $showHintLink = $exercise.querySelector(".exercise__show-hint");

    $showHintLink.addEventListener("click", (event) => {
      event.preventDefault();

      $hint.classList.add("exercise__hint--active");
      $showHintLink.parentNode.removeChild($showHintLink);
    });
  }
};
