<template>
<div class="login-popup">
  <div class="login-popup__container">
    <div class="login-popup__body">
      <div class="login-popup__info">
        <img
          class="login-popup__avatar"
          v-bind:src="require('@images/avatars/peace_half.png')" />
      </div>

      <div class="login-popup__form-and-actions">
        <h2 class="login-popup__headline">Log in</h2>

        <form action="/users" class="login-popup__form form" @submit.prevent="submitForm">
          <section class="form__form-group">
            <label for="email">Email</label>
            <input
              type="email"
              v-model="email"
              required
              :class="{ 'error': this.errors.email.length }"
              placeholder="foobar@gmail.com">

            <div class="form__form-group-errors" v-if="this.errors.email">
              <div class="form__form-group-error" v-for="error in this.errors.email" :key="error">
                {{ error }}
              </div>
            </div>
          </section>

          <section class="form__form-group">
            <label for="password">Password</label>
            <input
              type="password"
              v-model="password"
              required
              :class="{ 'error': this.errors.password.length }"
              placeholder="Your password">

            <div class="form__form-group-errors" v-if="this.errors.password">
              <div class="form__form-group-error" v-for="error in this.errors.password" :key="error">
                {{ error }}
              </div>
            </div>
          </section>

          <section class="form__form-group">
            <input
              type="submit"
              :value="submitCopy"
              class="form__submit btn"
              :class="{
                'btn--disabled': formDisabled
              }">
          </section>
        </form>

        <div class="login-popup__actions">
          <a
            v-on:click.prevent="toggleForm"
            class="login-popup__toggle-link"
            href="#">
            Don't have an account?
          </a>

          <a class="login-popup__reset-password-link" href="/reset_password">Forgot password?</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import CloseIcon from "@images/font_awesome/x-light.svg";

export default {
  components: {
    CloseIcon
  },
  data() {
    return {
      email: "",
      password: "",
      formDisabled: false,
      errors: {
        email: [],
        password: []
      }
    }
  },
  props: {
    afterAuth: {
      type: String,
      default: null
    }
  },
  computed: {
    submitCopy() {
      if(this.formDisabled) {
        return "Logging you in...";
      } else {
        return "LOG IN";
      }
    }
  },
  methods: {
    toggleForm() {
      this.$emit("toggleForm");
    },
    validateForm() {
      if(this.email.length === 0) {
        if(this.errors.email.indexOf("Please provide a valid email") < 0) {
          this.errors.email.push("Please provide a valid email");
        }

        return false;
      }

      this.errors.email = [];
      this.errors.password = [];

      return true;
    },
    submitForm() {
      if(this.formDisabled) {
        return;
      }

      this.formDisabled = true;

      if(!this.validateForm()) {
        this.formDisabled = false;

        return false;
      }

      let formData = new FormData();

      formData.append("email", this.email);
      formData.append("password", this.password);
      formData.append("after_auth", this.afterAuth);

      axios.post("/sessions.json", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        window.location = response.data.location;
      }).catch(error => {
        let errors = error.response.data.errors;

        if(Array.isArray(errors)) {
          this.errors.email = errors || [];
        } else if (typeof(errors) === "object") {
          this.errors.email = errors.email || [];
          this.errors.password = errors.password || [];
        } else {
          this.errors.email = ["Something went wrong. Try again or  contact support@sqlhabit.com"]
        }
      }).finally(() => {
        setTimeout(() => {
          this.formDisabled = false;
        }, 500);
      });
    }
  }
}
</script>

<style lang="scss">
.login-popup {
  width: 100%;

  border-radius: $px4;

  @include breakpoint($md) {
    width: $px720;
  }

  &__headline {
    margin: 0 0 $px24;
  }

  &__body {
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint($md) {
      flex-direction: row;
    }
  }

  &__info {
    width: 100%;
    position: relative;

    @include breakpoint($md) {
      width: 50%;
    }
  }

  &__form-and-actions {
    width: 100%;

    @include breakpoint($md) {
      width: 50%;
    }
  }

  &__form {
    margin-bottom: $px24;
  }

  &__avatar {
    max-width: 300px;

    @include breakpoint($md) {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;

    text-align: right;
  }

  &__toggle-link {
    display: block;
    margin-bottom: $px8;

    @include font-small;
    font-weight: 600;
    text-decoration: none;
  }

  &__reset-password-link {
    @include font-small;
    font-weight: 600;
    text-decoration: none;
  }
}
</style>
