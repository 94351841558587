import Vue from "modules/vue_twemoji";
import MarkLessonAsFinished from "components/mark-lesson-as-finished.vue";
import { store } from "store"

export default () => {
  const $markAsFinishedContainer = document.querySelector("#lesson-mark-as-completed");

  if($markAsFinishedContainer) {
    new Vue({
      name: "MarkAsFinished",
      el: $markAsFinishedContainer,
      store,
      render: h => h(MarkLessonAsFinished, {
        props: {
          postUrl: window.state.markAsFinishedUrl
        }
      })
    });
  }
};
