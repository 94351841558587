const $quizResult = document.querySelector(".quiz-result-page");

if($quizResult) {
  $quizResult.addEventListener("click", e => {
    if(e.target.classList.contains("quiz-item-explanation__show-details-link")) {
      e.preventDefault();

      const $link = e.target;
      const $linkContainer = $link.closest(".quiz-item-explanation__show-details");
      const $itemContainer = $link.closest(".quiz-item-explanation");
      const $taskDescription = $itemContainer.querySelector(".quiz-item-explanation__task-description");
      const $explanationHeadline = $itemContainer.querySelector(".quiz-item-explanation__explanation-headline");

      $linkContainer.remove();
      $taskDescription.classList.add("quiz-item-explanation__task-description--active");
      $explanationHeadline.classList.add("quiz-item-explanation__explanation-headline--active");
    }
  });
}
