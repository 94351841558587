<template>
  <div class="screenshot-gallery">
    <div class="screenshot-gallery__images">
      <div :class="imageClass(index)" v-for="(image, index) in images" :key="index">
        <img
          :src="image.src"
          class="screenshot-gallery__image"
          @click="nextImage()" />

        <div class="screenshot-gallery__caption">{{ image.caption }}</div>
      </div>
    </div>

    <div class="screenshot-gallery__nav">
      <div class="screenshot-gallery__nav-item"
           v-for="(image, index) in images"
          :key="index"
          :class="navItemClass(index)"
          @click="activateImage(index)"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    imageClass: function(index) {
      return this.activeImage == index ?
        "screenshot-gallery__image-container screenshot-gallery__image-container--active" :
        "screenshot-gallery__image-container";
    },
    navItemClass: function(index) {
      return this.activeImage == index ?
        "screenshot-gallery__nav-item screenshot-gallery__nav-item--active" :
        "screenshot-gallery__nav-item";
    },
    activateImage: function(index) {
      this.activeImage = index;
    },
    nextImage: function() {
      this.activeImage = (this.activeImage + 1) % this.images.length;
    }
  },
  data() {
    return {
      activeImage: 0,
      images: window.state.sqlHabitScreenshots
    }
  }
}
</script>

<style lang="scss">
.screenshot-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__images {
    margin-bottom: $px16;
  }

  &__image-container {
    display: none;

    &--active {
      display: block;
    }
  }

  &__image {
    display: block;
    width: 100%;
    margin-bottom: $px4;

    cursor: pointer;
  }

  &__caption {
    @include font-small;
  }

  &__nav {
    display: flex;
    align-items: center;
  }

  &__nav-item {
    width: $px8;
    height: $px8;

    border-radius: 50%;

    background-color: $grey-6;

    cursor: pointer;

    &--active {
      width: $px12;
      height: $px12;
    }

    &:not(:last-child) {
      margin-right: $px8;
    }
  }
}
</style>
