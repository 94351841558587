<template>
<div
  class="countdown-digits"
  v-if="isVisible">
  <div class="countdown-digits__digits">
    <div
      class="countdown-digits__digit"
      v-for="(digit, index) in digits"
      :key="`${label}-${index}`">
      {{ digit }}
    </div>

    <div class="countdown-digits__top-flap"></div>
    <div class="countdown-digits__bottom-flap"></div>
  </div>
  <div class="countdown-digits__label">
    {{ label }}
  </div>
</div>
</template>

<script>
export default {
  props: ["value", "label"],
  computed: {
    isVisible() {
      return Number.isInteger(this.value);
    },
    digits() {
      if(Number.isInteger(this.value)) {
        return this.value.toString().padStart(2, "0").split("");
      }
    }
  }
}
</script>

<style lang="scss">
.countdown-digits {
  $root: &;

  display: flex;
  flex-direction: column;
  position: relative;

  &__digits {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: $px4;
  }

  &__top-flap {
    display: block;
    position: absolute;
    top: -$px1;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: -1;

    border-radius: $px4 $px4 0 0;

    background-color: $blue-10;
  }

  &__bottom-flap {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: -1;

    border-radius: 0 0 $px4 $px4;

    background-color: $blue-10;
  }

  &__digit {
    @include font-large;
    font-weight: 700;

    &:first-child {
      margin-right: $px4;
    }
  }

  &__label {
    position: absolute;
    bottom: 0;
    left: 50%;

    text-align: center;

    @include font-legal;

    transform: translateX(-50%) translateY(100%);
  }
}
</style>
