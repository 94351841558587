export default (userSegment) => {
  const $customerPathNode = document.querySelector("[data-customer-path]");

  if($customerPathNode) {
    const { free, customerPath } = $customerPathNode.dataset;

    if(userSegment === "customer" || (free === "true" && userSegment !== "anonymous")) {
      window.location = customerPath;
      return;
    }
  }

  const $nonCustomerPathNode = document.querySelector("[data-non-customer-path]");

  if($nonCustomerPathNode) {
    const { free, nonCustomerPath } = $nonCustomerPathNode.dataset;

    if(userSegment === "anonymous") {
      window.location = nonCustomerPath;
      return;
    }

    if(userSegment !== "customer" && free !== "true") {
      window.location = nonCustomerPath;
      return;
    }
  }
};
