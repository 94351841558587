import Vue from "vue/dist/vue.esm";
import VueI18n from "vue-i18n";
import InfoPanel from "components/blog/info-panel";

Vue.use(VueI18n);

document.querySelectorAll(".js-blog-info-panel").forEach($el => {
  const copy = $el.innerHTML;

  new Vue({
    el: $el,
    i18n: new VueI18n({
      locale: "en"
    }),
    render: h => h(InfoPanel, {
      props: {
        copy
      }
    })
  });
});
