(function() {
  const $chaptersOvervew = document.querySelector(".chapters-overview");

  if(!$chaptersOvervew) {
    return;
  }


  const $expandCta = $chaptersOvervew.querySelector(".js-expand-chapters");

  $expandCta.addEventListener("click", e => {
    e.preventDefault();

    $chaptersOvervew.classList.add("chapters-overview--expanded");
  });
})();
