export default (isGuest) => {
  const $loggedInRedirectNode = document.querySelector("[data-logged-in-redirect-path]");

  if($loggedInRedirectNode) {
    const { loggedInRedirectPath } = $loggedInRedirectNode.dataset;

    if(isGuest === false) {
      window.location = loggedInRedirectPath;
      return;
    }
  }
};
