document.addEventListener("click", (e) => {
  if(e.target && e.target.matches(".js-smooth-scroll")) {
    e.preventDefault();

    const scrollToId = e.target.href.split('#')[1];
    const $target = document.querySelector(`#${scrollToId}`);

    if($target && $target.scrollIntoView) {
      $target.scrollIntoView({
        block: "center",
        behavior: "smooth",
        inline: "start"
      });
    }
  }
})
