import Vue from "modules/vue_twemoji";
import CompatibilityDrawer from "components/mdn/compatibility-drawer";

const $desktopCompatibilityTable = document.querySelector(".js-desktop-db-compatibility-table");

if ($desktopCompatibilityTable) {
  let vueDrawerContainer;
  const $drawerContainer = document.querySelector(".js-desktop-db-compatibility-drawer");
  const $drawerTableRow = document.querySelector(".js-desktop-db-compatibility-drawer-row");

  $desktopCompatibilityTable.addEventListener("click", (e) => {
    if (e.target && e.target.closest(".js-desktop-db-compatibility-status")) {
      e.preventDefault();

      const $dbStatus = e.target.closest(".js-desktop-db-compatibility-status");
      $desktopCompatibilityTable.querySelectorAll(".js-desktop-db-compatibility-status.active").forEach($s => $s.classList.remove("active"));
      $dbStatus.classList.add("active");

      if (!vueDrawerContainer) {
        vueDrawerContainer = new Vue({
          name: "CompatibilityDrawerContainer",
          el: $drawerContainer,
          render: h => h(CompatibilityDrawer, {
            ref: "drawer",
            props: {
              placement: "desktop"
            }
          })
        });
      }

      $drawerTableRow.classList.remove("hidden");
      vueDrawerContainer.$refs.drawer.compatibilityParams = JSON.parse($dbStatus.dataset.compatibilityParams);
    }
  })
}

const $mobileCompatibilityTable = document.querySelector(".js-mobile-db-compatibility-table");

if ($mobileCompatibilityTable) {
  let mountedVueDrawer;

  $mobileCompatibilityTable.addEventListener("click", (e) => {
    if (e.target && e.target.closest(".js-mobile-db-compatibility-row")) {
      e.preventDefault();

      const $dbStatusRow = e.target.closest(".js-mobile-db-compatibility-row");
      const dbName = $dbStatusRow.dataset.db;
      const $detailsRow = $mobileCompatibilityTable.querySelector(`.js-mobile-db-compatibility-details-row[data-for="${dbName}"]`);

      if ($detailsRow.classList.contains("block")) {
        return;
      }

      $mobileCompatibilityTable
        .querySelectorAll(".js-mobile-db-compatibility-row.active")
        .forEach($s => $s.classList.remove("active"));
      $dbStatusRow.classList.add("active");

      $mobileCompatibilityTable
        .querySelectorAll(".js-mobile-db-compatibility-details-row.block")
        .forEach($s => $s.classList.remove("block"));
      $detailsRow.classList.add("block");

      if (mountedVueDrawer) {
        mountedVueDrawer.$el.parentNode.removeChild(mountedVueDrawer.$el);
        mountedVueDrawer.$destroy();
      }

      const $vueContainer = document.createElement("div");
      $detailsRow.appendChild($vueContainer);

      mountedVueDrawer = new Vue({
        name: "CompatibilityDrawerContainer",
        el: $vueContainer,
        render: h => h(CompatibilityDrawer, {
          ref: "drawer",
          props: {
            placement: "mobile"
          }
        })
      });

      mountedVueDrawer.$refs.drawer.compatibilityParams = JSON.parse($dbStatusRow.dataset.compatibilityParams);
    }
  })
}
