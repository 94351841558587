export const mountExerciseSolvedCheckmark = (exerciseCompleted) => {
  if(!exerciseCompleted) {
    return;
  }

  const $solvedCheckmark = document.querySelector(".js-exercise-completion-checkmark");

  if(!$solvedCheckmark) {
    return;
  }

  $solvedCheckmark.classList.add("active");
};

export const mountLessonSolvedCheckmark = (lessonCompleted) => {
  if(!lessonCompleted) {
    return;
  }

  const $solvedCheckmark = document.querySelector(".js-lesson-completion-checkmark");

  if(!$solvedCheckmark) {
    return;
  }

  $solvedCheckmark.classList.add("active");
};
