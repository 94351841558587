(() => {
  const $modal = document.querySelector(".modal");

  if(!$modal) {
    return;
  }

  document.addEventListener("click", (e) => {
    if(!$modal.classList.contains("modal--active")) {
      return;
    }

    const $modalContainer = $modal.querySelector(".modal__container");
    let targetElement = e.target;

    do {
      if (targetElement == $modalContainer) {
        return;
      }

      targetElement = targetElement.parentNode;
    } while (targetElement);

    $modal.classList.remove("modal--active");
  });

  document.addEventListener("keydown", function(e) {
    if(!$modal.classList.contains("modal--active")) {
      return;
    }

    $modal.classList.remove("modal--active");
  });
})();
