var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"markdown-cheatsheet"},[_c('div',{staticClass:"markdown-cheatsheet__description"},[_vm._v("\n    Formatted with\n    "),_c('strong',[_c('a',{staticClass:"markdown-cheatsheet__toggle-link js-toggle-markdown-tip",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleCheatseet.apply(null, arguments)}}},[_vm._v("\n\n        Markdown\n        "),(!_vm.showCheatsheet)?_c('ArrowDown',{class:'markdown-cheatsheet__toggle-link-icon'}):_c('ArrowUp',{class:'markdown-cheatsheet__toggle-link-icon'})],1)])]),_vm._v(" "),(_vm.showCheatsheet)?_c('div',{staticClass:"markdown-cheatsheet__cheatsheet"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"markdown-cheatsheet__cheatsheet-item"},[_vm._v("\n      **bold**"),_c('br'),_vm._v(" "),_c('strong',[_vm._v("bold")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"markdown-cheatsheet__cheatsheet-item"},[_vm._v("\n      *italic*"),_c('br'),_vm._v(" "),_c('i',[_vm._v("italic")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"markdown-cheatsheet__cheatsheet-item"},[_vm._v("\n      ~~~pgsql"),_c('br'),_vm._v("\n      SELECT *"),_c('br'),_vm._v("\n      FROM users"),_c('br'),_vm._v("\n      ~~~\n      "),_c('pre',[_vm._v("        "),_c('code',{staticClass:"language-pgsql hljs"},[_vm._v("\n          SELECT *\n          FROM users\n        ")]),_vm._v("\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"markdown-cheatsheet__cheatsheet-item"},[_vm._v("\n      * item1"),_c('br'),_vm._v("\n      * item2"),_c('br'),_vm._v("\n      * item3\n      "),_c('ul',[_c('li',[_vm._v("item1")]),_vm._v(" "),_c('li',[_vm._v("item2")]),_vm._v(" "),_c('li',[_vm._v("item3")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"markdown-cheatsheet__cheatsheet-item"},[_vm._v("\n      ## Heading h2\n\n      "),_c('h2',[_vm._v("Heading h2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"markdown-cheatsheet__cheatsheet-item"},[_vm._v("\n      ## Heading h3\n\n      "),_c('h3',[_vm._v("Heading h3")])])
}]

export { render, staticRenderFns }