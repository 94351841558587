<template>
  <div class="signup-popup">
    <div class="signup-popup__container">
      <div class="signup-popup__body">
        <div class="signup-popup__info">
          <ul class="login-page__features-list">
            <li v-html="$options.filters.md(`:mag: Query any dataset`)" />
            <li v-html="$options.filters.md(`:busts_in_silhouette: Join %{usersCount} students`)" />
            <li v-html="$options.filters.md(`:chart_with_upwards_trend: Learn Data Analysis for Product Management and Marketing`)" />
            <li v-html="$options.filters.md(`:bar_chart: Learn SQL from basics to advanced`)" />
            <li v-html="$options.filters.md(`:briefcase: Practice for upcoming interviews`)" />
            <li v-html="$options.filters.md(`:speech_balloon: Get support from Community Forum`)" />
          </ul>
        </div>

        <div class="signup-popup__form-and-actions">
          <h2 class="signup-popup__headline">Create a free<br>SQL Habit account</h2>

          <form action="/users" class="signup-popup__form form" @submit.prevent="submitForm">
            <section class="form__form-group">
              <label for="email">Email</label>
              <input
                type="email"
                v-model="email"
                required
                :class="{ 'error': this.errors.email.length }"
                placeholder="foobar@gmail.com">

              <div class="form__form-group-errors" v-if="this.errors.email">
                <div class="form__form-group-error" v-for="error in this.errors.email" :key="error">
                  {{ error }}
                </div>
              </div>
            </section>

            <section class="form__form-group">
              <label for="password">Password</label>
              <input
                type="password"
                v-model="password"
                required
                :class="{ 'error': this.errors.password.length }"
                placeholder="Your password">

              <div class="form__form-group-errors" v-if="this.errors.password">
                <div class="form__form-group-error" v-for="error in this.errors.password" :key="error">
                  {{ error }}
                </div>
              </div>
            </section>

            <section class="form__form-group">
              <input
                type="submit"
                class="form__submit btn"
                :value="submitCopy"
                :class="{
                  'btn--disabled': formDisabled
                }">
            </section>
          </form>

          <div class="signup-popup__actions">
            <a
              v-on:click.prevent="toggleForm"
              class="signup-popup__toggle-link"
              href="#">
              Already have an account?
            </a>

            <a class="signup-popup__reset-password-link" href="/reset_password">Forgot password?</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>

  <script>
  import axios from "axios";
  import CloseIcon from "@images/font_awesome/x-light.svg";

  export default {
    name: "MdnSignupPopup",
    components: {
      CloseIcon
    },
    data() {
      return {
        email: "",
        password: "",
        formDisabled: false,
        errors: {
          email: [],
          password: []
        }
      }
    },
    props: {
      afterAuth: {
        type: String,
        default: null
      }
    },
    computed: {
      submitCopy() {
        if(this.formDisabled) {
          return "Signing you up...";
        } else {
          return "SIGN UP";
        }
      }
    },
    methods: {
      toggleForm() {
        this.$emit("toggleForm");
      },
      validateForm() {
        if(this.email.length === 0) {
          if(this.errors.email.indexOf("Please provide a valid email") < 0) {
            this.errors.email.push("Please provide a valid email");
          }

          return false;
        }

        if(this.password.length < 8) {
          if(this.errors.password.indexOf("Please provide a password with at least 8 characters") < 0) {
            this.errors.password.push("Please provide a password with at least 8 characters");
          }

          return false;
        }

        this.errors.email = [];
        this.errors.password = [];

        return true;
      },
      submitForm() {
        if(this.formDisabled) {
          return;
        }

        this.formDisabled = true;

        if(!this.validateForm()) {
          this.formDisabled = false;

          return false;
        }

        let formData = new FormData();

        formData.append("user[email]", this.email);
        formData.append("user[password]", this.password);
        formData.append("after_auth", this.afterAuth);

        axios.post("/users.json", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
          }
        }).then(response => {
          window.location = response.data.location;
        })
        .catch(error => {
          let errors = error.response.data.errors;

          if(Array.isArray(errors)) {
            this.errors.email = errors || [];
          } else {
            this.errors.email = errors.email || [];
            this.errors.password = errors.password || [];
          }
        }).finally(() => {
          setTimeout(() => {
            this.formDisabled = false;
          }, 500);
        });
      }
    }
  }
  </script>

  <style lang="scss">
.signup-popup {
    width: 100%;

    border-radius: $px4;

    @include breakpoint($md) {
      width: $px720;
    }

    &__headline {
      margin: 0 0 $px24;
    }

    &__body {
      display: flex;
      flex-direction: column-reverse;

      @include breakpoint($md) {
        flex-direction: row;
      }
    }

    &__info {
      width: 100%;

      @include breakpoint($md) {
        width: 50%;
      }
    }

    &__features-list {
      li {
        &:not(:last-child) {
          margin-bottom: $px24;
        }
      }
    }

    &__form-and-actions {
      width: 100%;

      @include breakpoint($md) {
        width: 50%;
      }
    }

    &__form {
      margin-bottom: $px24;
    }

    &__actions {
      display: flex;
      flex-direction: column;

      text-align: right;
    }

    &__toggle-link {
      display: block;
      margin-bottom: $px8;

      @include font-small;
      font-weight: 600;
      text-decoration: none;
    }

    &__reset-password-link {
      @include font-small;
      font-weight: 600;
      text-decoration: none;
    }
  }
  </style>
