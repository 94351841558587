var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"practice-exercise",attrs:{"id":_vm.exercise.slug,"data-id":_vm.exercise.id}},[_c('div',{staticClass:"practice-exercise__container"},[_c('h3',{staticClass:"practice-exercise__name"},[_vm._v("\n      "+_vm._s(_vm.exercise.position)+". "+_vm._s(_vm.exercise.name)+"\n\n      "),_c('div',{staticClass:"practice-exercise__status",class:{
          'practice-exercise__status--active': _vm.exercise.solved
        }},[_c('CheckIcon',{staticClass:"practice-exercise__status-icon"})],1)]),_vm._v(" "),_c('div',{staticClass:"practice-exercise__info"},[_c('div',{staticClass:"practice-exercise__info-badge"},_vm._l((_vm.exercise.difficulty),function(n){return _c('HotPepperIcon',{key:n,staticClass:"practice-exercise__difficutly-icon"})}),1),_vm._v(" "),_c('div',{staticClass:"practice-exercise__info-badge"},[_c('DatabaseIcon',{staticClass:"practice-exercise__info-badge-icon"}),_vm._v("\n        "+_vm._s(_vm.exercise.dataset_name)+"\n      ")],1)]),_vm._v(" "),_c('div',{staticClass:"practice-exercise__summary",domProps:{"innerHTML":_vm._s(_vm.exercise.summary)}}),_vm._v(" "),_c('div',{staticClass:"practice-exercise__footer"},[_c('div',{staticClass:"practice-exercise__actions"},[(_vm.exerciseAvailable)?_c('a',{staticClass:"practice-exercise__cta btn btn--flex",class:{
            'js-open-signup-popup': _vm.isGuest
          },attrs:{"href":_vm.ctaUrl}},[_vm._v("\n          Start exercise\n        ")]):_c('a',{staticClass:"practice-exercise__cta btn btn--flex",class:{
            'js-open-signup-popup': _vm.isGuest
          },attrs:{"href":_vm.ctaUrl}},[_c('LockIcon',{staticClass:"practice-exercise__cta-icon"}),_vm._v("\n            Buy full access\n        ")],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }