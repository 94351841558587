import Vue from "vue/dist/vue.esm";
import MarkdownCheatsheet from "components/forum/markdown-cheatsheet";

let $containers = document.querySelectorAll(".js-markdown-cheetsheet");

$containers.forEach($container => {
  new Vue({
    el: $container,
    render: h => h(MarkdownCheatsheet)
  });
});
