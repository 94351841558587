const actions = {
  setCompletedFlag: ({commit}, input) => {
    commit("setCompletedFlag", input);
  },
  setSlug: ({commit}, input) => {
    commit("setSlug", input);
  }
};

export default actions;
