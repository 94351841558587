import axios from "axios";

const loadMobileNavbarPartial = ($mobileNavbar) => {
  const params = {
    hide_menu: $mobileNavbar.dataset.hideMenu,
    hide_signup_button: $mobileNavbar.dataset.hideSignupButton
  };

  axios.get("/api/partials/mobile-navbar.json", {
    params: params
  }).then(response => {
    const params = response.data;

    if(params.partial) {
      $mobileNavbar.outerHTML = params.partial;
    }
  });
}

export default loadMobileNavbarPartial;
