const actions = {
  setData: ({commit}, payload) => {
    commit("data", payload);
  },
  setChartType: ({commit}, type) => {
    commit("chartType", type);
  },
  setChartTitle: ({commit}, title) => {
    commit("title", title);
  },
  setXAxisTitle: ({commit}, title) => {
    commit("xAxisTitle", title);
  },
  setYAxisTitle: ({commit}, title) => {
    commit("yAxisTitle", title);
  },
  setXAxis: ({commit}, payload) => {
    commit("xAxis", payload);
  },
  setYAxis: ({commit}, payload) => {
    commit("yAxis", payload);
  },
  setTableColumns: ({commit}, payload) => {
    commit("tableColumns", payload);
  },
  setSeries: ({commit}, payload) => {
    commit("seriesColumns", payload);
  },
  setStacked: ({commit}, isStacked) => {
    commit("stacked", isStacked);
  },
  setLegendPosition({commit}, position) {
    commit("legendPosition", position);
  },
  setDatabases({commit}, payload) {
    commit("databases", payload);
  },
  setCurrentDatabaseId({commit}, datasetId) {
    commit("currentDatabaseId", datasetId);
  },
  setShowAllXAxisLabels: ({commit}, checked) => {
    commit("showAllXAxisLabels", checked);
  },
  setSqlReference: ({commit}, reference) => {
    commit("sqlReference", reference);
  },
  clearState: ({commit}) => {
    commit("clearState");
  },
};

export default actions;
