import Vue from "modules/vue_twemoji";
import Bookmark from "components/bookmark";

const initBookmarkLink = ($container) => {
  const contentType = $container.dataset.contentType;
  const contentSlug = $container.dataset.contentSlug;
  const iconSize = parseInt($container.dataset.iconSize || 16, 10);
  const iconOnly = $container.dataset.iconOnly === "true" ? true : false;
  const initActive = $container.dataset.initActive === "true" ? true : false;

  new Vue({
    el: $container,
    render: h => h(Bookmark, {
      props: {
        contentType,
        contentSlug,
        iconSize,
        iconOnly,
        initActive
      }
    })
  });
};

export default () => {
  document.querySelectorAll(".js-bookmark").forEach($container => {
    initBookmarkLink($container);
  });
};
