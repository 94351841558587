import axios from "axios";

const loadPracticeExerciseCta = ($practiceExerciseCta) => {
  const params = {
    slug: $practiceExerciseCta.dataset.slug
  };

  axios.get("/api/partials/practice-exercise-cta.json", {
    params: params
  }).then(response => {
    const params = response.data;

    if(params.partial) {
      $practiceExerciseCta.outerHTML = params.partial;
    }
  });
}

export default loadPracticeExerciseCta;
