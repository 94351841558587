import Vue from "vue/dist/vue.esm";
import AuthPopup from "components/auth-popup";
import twemoji from "twemoji";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

let md = require("markdown-it")({
  html: true,
  linkify: true,
  typographer: true
});
let emoji = require("markdown-it-emoji");

md.use(emoji);

md.renderer.rules.emoji = function(token, idx) {
  return twemoji.parse(token[idx].content, {
    base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/'
  });
};

let stats = window.stats || {};

Vue.filter("md", value => {
  for (const key in stats) {
    value = value.replace(`%{${key}}`, stats[key]);
  }

  return md.renderInline(value);
});

const mountPopup = (cta) => {
  let afterPath = cta.dataset.afterAuth;
  let signupFormActiveProp = cta.classList.contains("js-open-signup-popup");
  const signupPopupType = cta.dataset.guestPopup;

  if(window.popup) {
    window.popup.$refs.auth_popup.show(afterPath, signupFormActiveProp, signupPopupType);
  } else {
    const $container = document.querySelector(".js-signup-popup");
    window.popup = new Vue({
      i18n: new VueI18n({
        locale: "en"
      }),
      el: $container,
      render: h => h(AuthPopup, {
        ref: "auth_popup",
        props: {
          visible: true,
          afterAuthProp: afterPath,
          signupFormActiveProp: signupFormActiveProp,
          signupPopupType
        }
      })
    });
  }
}

document.addEventListener("click", e => {
  if(e.target && e.target.matches && (e.target.matches(".js-open-signup-popup") || e.target.matches(".js-open-login-popup"))) {
    e.preventDefault();

    mountPopup(e.target);
  }
});

document.addEventListener("click", (e) => {
  if(!e.target.classList) {
    return;
  }

  if(e.target.classList.contains("js-open-signup-popup")) {
    return;
  }

  if(e.target.classList.contains("js-open-login-popup")) {
    return;
  }

  if(!window.popup) {
    return;
  }

  if(window.popup.$refs && window.popup.$refs.auth_popup && !window.popup.$refs.auth_popup.isVisible()) {
    return;
  }

  const $authPopup = window.popup.$refs.auth_popup.$el.querySelector(".auth-popup");
  let targetElement = e.target;

  do {
    if (targetElement == $authPopup) {
      return;
    }

    if(targetElement.classList && targetElement.classList.contains("signup-popup")) {
      return;
    }

    if(targetElement.classList && targetElement.classList.contains("login-popup")) {
      return;
    }

    targetElement = targetElement.parentNode;
  } while (targetElement);

  e.preventDefault();

  window.popup.$refs.auth_popup.hide();
});

document.addEventListener("keydown", function(e) {
  if(e.key !== "Escape" && e.key !== "Esc") {
    return;
  }

  if(!window.popup) {
    return;
  }

  if(!window.popup.$refs.auth_popup.isVisible()) {
    return;
  }

  window.popup.$refs.auth_popup.hide();
});
