document.querySelectorAll(".js-share-on-linkedin").forEach(el => {
  el.addEventListener("click", e => {
    e.preventDefault();

    let url = "https://www.linkedin.com/sharing/share-offsite/";

    const ogUrl = document.querySelector("meta[property='og:url']").content;

    url += "?url=" + encodeURIComponent(ogUrl);

    const left = (screen.width - 550) / 2;
    const top = (screen.height - 285) / 3;

    window.open(
      url,
      "LinkedIn",
      `toolbar=no,top=${top},left=${left},status=no,menubar=no,scrollbars=yes,width=550,height=285,resizable=1`
    );
  });
});
