(function() {
  const $navbar = document.querySelector(".mobile-navbar");

  if(!$navbar) {
    return;
  }

  $navbar.addEventListener("click", (e) => {
    if(e.target && e.target.closest(".js-toggle-menu")) {
      $navbar.classList.toggle("mobile-navbar--active");
    }
  });
})();
