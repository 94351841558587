import externalTooltipHandler from "./tooltip";
import { MAX_X_AXIS_TICKS } from "./helpers";
import {enUS} from "date-fns/locale";

const getters = {
  chartTypes: (state) => {
    return state.types;
  },
  fields: (state) => {
    return state.fields;
  },
  tableColumns: (state) => {
    return state.tableColumns;
  },
  result: (state) => {
    return state.result;
  },
  chartType: (state) => {
    return state.type;
  },
  type: (state) => {
    return state.type;
  },
  xAxis(state) {
    return state.xAxis;
  },
  yAxis(state) {
    return state.yAxis;
  },
  xAxisType(state) {
    return state.xAxisType;
  },
  xAxisTitle: (state) => {
    return state.xAxisTitle;
  },
  yAxisTitle: (state) => {
    return state.yAxisTitle;
  },
  title(state) {
    return state.title;
  },
  stacked: (state) => {
    return state.stacked;
  },
  seriesColumns: (state) => {
    return state.seriesColumns;
  },
  series: (state) => {
    return state.series;
  },
  labels: (state) => {
    return state.labels;
  },
  datasets: (state) => {
    return state.datasets;
  },
  databases: (state) => {
    return state.databases;
  },
  currentDatabaseId: (state) => {
    return state.currentDatabaseId;
  },
  currentDatabase: (state) => {
    if(state.databases.length === 0) {
      return {};
    }

    return state.databases.find(d => d.id === state.currentDatabaseId);
  },
  datasetUpdatedAt: (state) => {
    return state.datasetUpdatedAt;
  },
  fieldTypes(state) {
    return state.fieldTypes;
  },
  legendPosition(state) {
    return state.legendPosition;
  },
  legendPositions(state) {
    return state.legendPositions;
  },
  hasErrors(state) {
    return Object.keys(state.errors).length > 0;
  },
  errors(state) {
    return state.errors;
  },
  showAllXAxisLabels(state) {
    return state.showAllXAxisLabels
  },
  test(state) {
    return state.datasets.map(d => d.label);
  },
  showLegend(state) {
    const labels = state.datasets.map(d => d.label);

    return !(labels.length === 1 && labels[0] === "default");
  },
  optionsPlugins(state, getters) {
    return {
      title: {
        display: !!state.title,
        text: state.title,
        align: "start",
        color: "#323F4B",
        padding: {
          bottom: 16
        },
        font: {
          size: 24
        }
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler
      },
      legend: {
        display: getters.showLegend,
        position: state.legendPosition,
        align: "start",
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          pointStyle: "circle",
          usePointStyle: true,
          color: "#323F4B",
          font: {
            size: 12
          }
        }
      }
    }
  },
  optionsXAxis(state) {
    return {
      type: state.xAxisType,
      time: {
        // unit: state.xAxisTimeUnit,
        displayFormats: { // https://date-fns.org/v2.27.0/docs/format
          millisecond: "H:mm:ss.SSS",
          second: "H:mm:ss",
          minute: "H:mm",
          hour: "d MMM H:mm",
          day: "d MMM",
          week: "ll",
          month: "MMM yy",
          quarter: "QQQ yyyy",
          year: "yyyy",
        }
      },
      adapters: {
        date: {
          locale: enUS
        }
      },
      stacked: state.type === "bar" && state.stacked,
      display: true,
      title: {
        display: !!state.xAxisTitle,
        text: state.xAxisTitle,
        color: "#323F4B",
      },
      scaleLabel: {
        display: true,
        labelString: 'Date'
      },
      ticks: {
        autoSkip: !state.showAllXAxisLabels,
        color: "#323F4B",
        font: {
          size: 12
        },
        source: "auto", // https://github.com/chartjs/Chart.js/pull/5933"
        maxTicksLimit: state.showAllXAxisLabels ? null : MAX_X_AXIS_TICKS
      },
      grid: {
        borderColor: "#E4E7EB",
        display: true,
        drawOnChartArea: false,
        offset: false
      },
      offset: true
    }
  },
  optionsYAxis(state) {
    return {
      stacked: state.type === "bar" && state.stacked,
      display: true,
      title: {
        display: !!state.yAxisTitle,
        text: state.yAxisTitle,
        color: "#323F4B",
      },
      scaleLabel: {
        display: true,
        labelString: 'Index Returns'
      },
      ticks: {
        color: "#323F4B",
        font: {
          size: 12
        }
      },
      grid: {
        drawBorder: false,
        color: "#E4E7EB",
        tickLength: 0
      }
    }
  },
  sqlReference(state) {
    return state.sqlReference;
  }
};

export default getters;
