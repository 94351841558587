import axios from "axios";

const loadFullScreenPromoPartial = ($fullScreenPromo) => {
  const params = {};

  axios.get("/api/partials/full-screen-promo.json", {
    params: params
  }).then(response => {
    const params = response.data;

    if(params.partial) {
      $fullScreenPromo.outerHTML = params.partial;
    }
  });
}

export default loadFullScreenPromoPartial;
