let mathjaxLoaded = false;

if(!window.MathJax) {
  window.MathJax = {
    tex: {
      inlineMath: [["$", "$"], ["\\(", "\\)"]]
    },
    svg: {
      fontCache: "global"
    },
    chtml: {
      displayAlign: "left"
    }
  };
}

export const loadMathjax = () => {
  if(mathjaxLoaded) {
    return;
  }

  const script = document.createElement("script");
  script.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js";
  script.async = true;
  script.onload = () => {
    const $formulas = document.querySelectorAll(".js-mathjax");

    $formulas.forEach($formula => {
      $formula.classList.add("visible");
    });

    mathjaxLoaded = true;
  }

  document.head.appendChild(script);
}

export const mountMathjax = () => {
  if(document.querySelectorAll(".js-mathjax").length > 0) {
    loadMathjax();
  }
};

mountMathjax();
