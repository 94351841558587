import state from "./chart/state";
import getters from "./chart/getters";
import mutations from "./chart/mutations";
import actions from "./chart/actions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
