<template>
  <DropdownMenu name="Access">
    <DropdownMenuItem
      name="Free"
      value="free:true" />

    <DropdownMenuItem
      name="Premium"
      value="free:false" />
  </DropdownMenu>
</template>

<script>
import DropdownMenu from "./dropdown-menu";
import DropdownMenuItem from "./dropdown-menu-item";

export default {
  name: "PracticeStatusMenu",
  components: {
    DropdownMenu,
    DropdownMenuItem
  }
}
</script>
